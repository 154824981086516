import React from "react";
import { Statistic, Card, Row, Col } from "antd";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from "recharts";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const pieData = [
  { name: "Out for delivery", value: 5 },
  { name: "Completed", value: 10 },
  { name: "P.Completed", value: 2 },
  { name: "Failed", value: 1 },
  { name: "Assigned", value: 30 },
  { name: "Unassigned", value: 50 },
  { name: "Picked UP", value: 15 },
];

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#8B0000",
  "#FF8042",
  "#8A2BE2",
  "#20B2AA",
  "#FFA500",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Dashboard = () => {
  let newDate = new Date();
  const title =
    "Total distance travelled (" + newDate.toLocaleDateString() + ")";

  const data = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "March",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "April",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "July",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "Aug",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "Sept",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "Oct",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "Nov",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "Dec",
      uv: 3490,
      pv: 4300,
    },
  ];

  // const [activeIndex, setActiveIndex] = useState(0);
  // const onPieEnter = useCallback(
  //   (_, index) => {
  //     setActiveIndex(index);
  //   },
  //   [setActiveIndex]
  // );
  return (
    <div className="site-layout-content">
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Daily Completed Jobs"
              value=""
              formatter={() => {
                return (
                  <>
                    <div>100</div>
                    <div>
                      <span style={{ fontSize: "16px" }}>YoY</span>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "green",
                          paddingLeft: "10px",
                        }}
                      >
                        <ArrowUpOutlined />
                        11%
                      </span>
                    </div>
                  </>
                );
              }}
              //   precision={2}
              //   valueStyle={{ color: "black" }}
              //   prefix={<ArrowUpOutlined />}
              //   suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title={title}
              formatter={() => {
                return (
                  <>
                    <div>1,201,254km</div>
                    <div>
                      <span style={{ fontSize: "16px" }}>Weekly compare</span>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "red",
                          paddingLeft: "10px",
                        }}
                      >
                        <ArrowDownOutlined />
                        6%
                      </span>
                    </div>
                  </>
                );
              }}
              //   value={9.3}
              //   precision={2}
              // valueStyle={{ color: "#cf1322" }}
              //  prefix={<ArrowDownOutlined />}
              //  suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total COD Collection"
              formatter={() => {
                return (
                  <>
                    <div>62</div>
                    <div>
                      <span style={{ fontSize: "16px" }}>Weekly compare</span>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "green",
                          paddingLeft: "10px",
                        }}
                      >
                        <ArrowUpOutlined />
                        40%
                      </span>
                    </div>
                  </>
                );
              }}
              //   value={9.3}
              //   precision={2}
              //   valueStyle={{ color: "#cf1322" }}
              //   prefix={<ArrowDownOutlined />}
              //   suffix="%"
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "20px",
              //   width: "50%",
              //   verticalAlign: "bottom",
              //   display: "inline-block",
            }}
          >
            <div
              style={{
                marginLeft: "24px",
                fontSize: "14px",
                color: "gray",
                paddingTop: "10px",
              }}
            >
              Some barchart
            </div>
            <ResponsiveContainer width={"100%"} height={300}>
              <BarChart
                // width={800}
                // height={200}
                data={data}
                margin={{ top: 20, right: 30, bottom: 40 }}
                style={{ marginTop: "20px" }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{ position: "relative" }} />
                <Bar dataKey="pv" fill="#1E90FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col span={8}>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "20px",
              //   width: "50%",
              //   display: "inline-block",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "24px",
                fontSize: "14px",
                color: "gray",
                paddingTop: "10px",
              }}
            >
              Some piechart
            </div>
            <ResponsiveContainer width={"100%"} height={320}>
              <PieChart height={320}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={{
                    top: "50%",
                    right: 0,
                    transform: "translate(0, -50%)",
                    lineHeight: "24px",
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "20px",
              //   width: "50%",
              //   display: "inline-block",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "24px",
                fontSize: "14px",
                color: "gray",
                paddingTop: "10px",
              }}
            >
              Some piechart
            </div>
            <ResponsiveContainer width={"100%"} height={320}>
              <LineChart
                width={730}
                height={250}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
