import http from "../http-common";
const create = (data) => {
  return http.post("/items", data);
};
const getAllByJobId = (job_id) => {
  return http.get(`/items/${job_id}`);
};

const update = (id) => {
  return http.put(`/items/${id}`);
};
const remove = (id) => {
  return http.delete(`/items/${id}`);
};

const itemObj = {
  create,
  getAllByJobId,
  update,
  remove,
};

export default itemObj;
