import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Spin } from "antd";
import VehicleService from "../../services/VehicleService";
import SettingService from "../../services/SettingService";

const { Option } = Select;
const VehicleUpdateForm = ({
  visible,
  vehicleId,
  title,
  isHidden,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [groupsData, setGroupsData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(vehicleId);
    if (vehicleId) {
      (async () => {
        setLoading(true);
        await VehicleService.get(vehicleId).then((response) => {
          form.setFieldsValue({
            driver_id: response.data.driver_id,
            name: response.data.name,
            vehicle_no: response.data.vehicle_no,
            zones: response.data.zones,
            groups: response.data.groups,
            phone_no: response.data.phone_no,
          });
        });
        setLoading(false);
      })();
    }
    setLoading(true);
    SettingService.getAllByEntity("group")
      .then((response) => {
        setGroupsData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, [form, vehicleId]);
  return (
    <Modal
      style={{ top: 20 }}
      visible={visible}
      title={title}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        onCancel(form);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            if (vehicleId) {
              onUpdate(vehicleId, values);
            } else {
              onCreate(values);
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 4 },
            md: { span: 6 },
            lg: { span: 6 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
          }}
          // layout="inline"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="driver_id"
            label="Driver ID"
            rules={[
              {
                required: true,
                message: "Please input driver id!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicle_no"
            label="Vehicle Number"
            rules={[
              {
                required: true,
                message: "Please input vehicle number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="speedLimit" label="Speed Limit">
            <Input defaultValue={70} addonAfter="km/h" disabled />
          </Form.Item>
          <Form.Item name="stationaryLimit" label="Stationary Limit">
            <Input defaultValue={70} addonAfter="km/h" disabled />
          </Form.Item>
          <Form.Item
            name="phone_no"
            label="Mobile Number"
            rules={[
              {
                required: true,
                message: "Please input mobile number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="groups" label="Groups">
            <Select
              placeholder="Select group"
              // onChange={(value, element) => {
              //   setSelectedVehicle(value);
              //   // setSelectedVehicle(element.key);
              // }}
              allowClear
            >
              {groupsData ? (
                groupsData.map((option) => (
                  <Option key={option.id} value={option.name}>
                    {option.name}
                  </Option>
                ))
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
          <Form.Item name="zones" label="Zones">
            <Input />
          </Form.Item>
          <Form.Item name="vehicleTypes" label="Vehicle Types">
            <Input />
          </Form.Item>
          <Form.Item name="latitude" label="Latitude" hidden={isHidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="longitude" label="Longitude" hidden={isHidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="osVersion" label="OS Version" hidden={isHidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="appVersion" label="App Version" hidden={isHidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="createdAt" label="Created At" hidden={isHidden}>
            <Input disabled />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default VehicleUpdateForm;
