import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  // DatePicker,
  Select,
  Table,
  Button,
  InputNumber,
  Spin,
  Image,
  Space,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import JobService from "../../services/JobService";
import VehicleService from "../../services/VehicleService";
import JobStatusTraceService from "../../services/JobStatusTraceService";
import { JobStatusEnum } from "../../enumClass/JobStatus";
import ItemService from "../../services/ItemService";
import DatePicker from "../../components/DatePicker";

const { Option } = Select;
const { TextArea } = Input;

const statusColumns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, row) => JobStatusEnum[text],
  },
  {
    title: "Date Time",
    dataIndex: "datetime",
    key: "datetime",
    render: (text, row) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Driver",
    dataIndex: "driver",
    key: "driver",
  },
  {
    title: "User",
    dataIndex: "user_uuid",
    key: "user_uuid",
  },
  {
    title: "Proof",
    dataIndex: "proof",
    key: "proof",
    render: (text, row) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Map",
    dataIndex: "map",
    key: "map",
  },
];

const DeliveriesForm = ({
  visible,
  jobId,
  onCreate,
  onUpdate,
  onCancel,
  formTitle,
  groupsData,
}) => {
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [statusData, setStatusData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);
  const [podSignature, setPodSignature] = useState();
  const [podImage, setPodImage] = useState([]);

  const itemColumns = [
    { title: "Batch Number", dataIndex: "batch_num", key: "batch_num" },
    { title: "Marking Number", dataIndex: "marking_num", key: "marking_num" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    {
      title: "Reject Quantity",
      dataIndex: "reject_quantity",
      key: "reject_quantity",
    },
    {
      title: "Reject Reason",
      dataIndex: "reject_reason",
      key: "reject_reason",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<DeleteOutlined />}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            var items = [...itemData];
            var filteredItems = items.filter((i) => i.id !== record.id);
            setItemData(filteredItems);
          }}
        ></Button>
      ),
    },
  ];

  useEffect(() => {
    async function getVehicle() {
      setLoading(true);
      await VehicleService.getAll()
        .then((resp) => {
          setVehicleData(resp.data);
        })
        .catch((err) => {
          console.log("vehicle data err:", err);
        });
      setLoading(false);
    }

    async function getItems(jobId) {
      setLoading(true);
      await ItemService.getAllByJobId(jobId)
        .then((response) => {
          const newResult = response.data.map((r) => {
            return { ...r, key: r.id };
          });
          console.log("setItemData", itemData, newResult, jobId);
          if (Array.isArray(newResult) && newResult.length) {
            setItemData(newResult);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }

    async function getJobStatus(jobId) {
      await JobStatusTraceService.getAllByJobId(jobId)
        .then((response) => {
          const newResult = response.data.map((r) => {
            return { ...r, key: r.id };
          });
          setStatusData(newResult);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function fetchData(form) {
      setLoading(true);
      await JobService.get(jobId)
        .then((response) => {
          console.log("AAAAAAAAAA", response.data);
          setPodImage(response.data.job_photos);
          setPodSignature(response.data.signature);
          form.setFieldsValue({
            id: response.data.id,
            address: response.data.address,
            batch_num: response.data.batch_num,
            postal_code: response.data.postal_code,
            date: dayjs(response.data.date),
            contact: response.data.contact,
            recipient: response.data.recipient,
            pts: response.data.pts,
            quantity: response.data.quantity,
            group: response.data.group,
            assign_to: response.data.assign_to,
            job_status: response.data.job_status,
            instructions: response.data.instructions,
            marking_number: response.data.marking_number,
            pod_time: dayjs(response.data.pod_time).isValid()
              ? dayjs(response.data.pod_time)
              : null,
            reasons: response.data.reasons,
            received_by: response.data.received_by,
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }

    getVehicle();
    if (jobId) {
      fetchData(form);
      getJobStatus(jobId);
      if (!itemData) {
        getItems(jobId);
      }
    }
  }, [jobId, form, itemData]);

  const ShowItemForm = () => {
    setIsAddItemModalVisible(true);
  };

  const addItem = (value) => {
    setIsAddItemModalVisible(false);
    var items = [...itemData];
    if (items.length > 0) {
      var result = Math.max.apply(
        Math,
        items.map(function (o) {
          return o.id;
        })
      );
      console.log("result", result);
      value["id"] = result + 1;
      value["key"] = result + 1;
    } else {
      value["id"] = 1;
      value["key"] = 1;
    }
    items.push(value);
    setItemData(items);
    console.log("Addd", items);
  };

  return (
    <Modal
      style={{ top: 20 }}
      width={900}
      visible={visible}
      title={formTitle}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        setItemData();
        onCancel(form);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values, text) => {
            console.log("values", values);
            form.resetFields();
            if (jobId) {
              // values["date"] = dayjs(values["date"]);
              onUpdate(jobId, values, itemData);
            } else {
              onCreate(values, itemData);
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Spin spinning={loading}>
        {jobId ? (
          <Table
            dataSource={statusData}
            columns={statusColumns}
            scroll={{ x: true }}
            pagination={false}
          />
        ) : (
          <></>
        )}
        <Form
          form={form}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 4 },
            md: { span: 6 },
            lg: { span: 6 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
          }}
          // layout="inline"
          // name="form_in_modal"
          // initialValues={{
          //    modifier: "public",
          // }}
        >
          <h3>Job Details</h3>
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={async () => {
                console.log("jobid", jobId);
                await JobService.getPodPdf(jobId)
                  .then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.data], { type: "application/pdf" })
                    );
                    var link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      `pod_${dayjs().format("YYYYMMDD")}.pdf`
                    );
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((err) => {
                    console.log("podpdf", err);
                  });
              }}
            >
              POD
            </Button>
          </div>

          <Form.Item name="job_status" label="Job Status">
            {/* <Input hidden disabled value={jobStatus} /> */}
            <Select
              placeholder="Select a job status"
              onChange={(value) => {
                console.log("onchangel;", value);
              }}
            >
              {/* todo: figure this out! dynamic Option will show value in display with setFieldValue */}
              {/* {Object.entries(JobStatusEnum)
              // .sort(([, a], [, b]) => a - b)
              .map(([value, label]) => {
                return (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                );
              })} */}
              <Option value={0}>Info Received</Option>
              <Option value={1}>In Transit</Option>
              <Option value={2}>Dispatched</Option>
              <Option value={3}>Assigned</Option>
              <Option value={4}>Out For Delivery</Option>
              <Option value={5}>Completed</Option>
              <Option value={6}>Partially Completed</Option>
              <Option value={7}>Failed</Option>
              <Option value={8}>On Hold</Option>
              <Option value={9}>Return</Option>
            </Select>
          </Form.Item>
          <Form.Item name="note" label="Note">
            <TextArea />
          </Form.Item>
          <Form.Item name="pod" label="POD">
            <Space
              direction="horizontal"
              size="small"
              style={{ display: "flex" }}
            >
              {Array.isArray(podImage) && podImage.length > 0 ? (
                podImage.map((option) => (
                  <Image
                    key={option.id}
                    width={150}
                    src={`https://api.lokalproject.com/${option.photo}`}
                    // src={`http://localhost:5000/${option.photo}`}
                  ></Image>
                ))
              ) : (
                <></>
              )}
              <Image width={150} src={podSignature ? podSignature : ""}></Image>
            </Space>
          </Form.Item>
          {jobId ? (
            <Form.Item name="tracker_link" label="Tracking link">
              <a
                href="http://localhost:3000/t"
                target="_blank"
                rel="noreferrer"
              >
                http://localhost:9000/t
              </a>
            </Form.Item>
          ) : (
            <></>
          )}
          <hr></hr>
          <h3>Details</h3>
          <Form.Item
            name="batch_num"
            label="Batch Number"
            rules={[
              {
                required: true,
                message: "Please input batch number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please input address!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item name="postal_code" label="Postal Code">
            <Input />
          </Form.Item>
          <Form.Item name="contact" label="Contact">
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker allowClear={false} />
          </Form.Item>
          <Form.Item name="recipient" label="Recipient">
            <Input />
          </Form.Item>
          <Form.Item name="pts" label="PTS">
            <Input />
          </Form.Item>
          <Form.Item name="quantity" label="Quantity">
            <Input />
          </Form.Item>
          <Form.Item name="group" label="Group">
            <Select
              placeholder="Select group"
              // onChange={(value, element) => {
              //   setSelectedVehicle(value);
              //   // setSelectedVehicle(element.key);
              // }}
              allowClear
            >
              {groupsData ? (
                groupsData.map((option) => (
                  <Option key={option.id} value={option.name}>
                    {option.name}
                  </Option>
                ))
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
          <Form.Item name="assign_to" label="Assign To">
            <Select
              placeholder="Select vehicle"
              // onChange={(value, element) => {
              //   setSelectedVehicle(value);
              //   // setSelectedVehicle(element.key);
              // }}
              allowClear
            >
              {vehicleData ? (
                vehicleData.map((option) => (
                  <Option key={option.vehicle_uuid} value={option.vehicle_uuid}>
                    {option.name}
                  </Option>
                ))
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
          <Form.Item name="instructions" label="Instructions">
            <Input />
          </Form.Item>
          <Form.Item name="marking_number" label="Marking Number">
            <Input />
          </Form.Item>
          <Form.Item name="pod_time" label="POD Time">
            <DatePicker showTime />
          </Form.Item>
          <Form.Item name="reasons" label="Reasons">
            <Input />
          </Form.Item>
          <Form.Item name="received_by" label="Received By">
            <Input />
          </Form.Item>
        </Form>
        <h3>Items</h3>
        <Table
          dataSource={itemData}
          columns={itemColumns}
          scroll={{ x: true }}
          pagination={false}
        />
        <Button type="primary" style={{ marginTop: 16 }} onClick={ShowItemForm}>
          Add item
        </Button>
        <Modal
          title="Item details"
          visible={isAddItemModalVisible}
          onOk={() => {
            itemForm
              .validateFields()
              .then((values, text) => {
                console.log("text", text);
                itemForm.resetFields();
                addItem(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          onCancel={() => {
            itemForm.resetFields();
            setIsAddItemModalVisible(false);
          }}
        >
          <Form
            form={itemForm}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 4 },
              md: { span: 6 },
              lg: { span: 6 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
              md: { span: 16 },
              lg: { span: 16 },
            }}
          >
            <Form.Item name="batch_num" label="Batch Number">
              <Input />
            </Form.Item>
            <Form.Item name="marking_num" label="Marking Number">
              <Input />
            </Form.Item>
            <Form.Item name="quantity" label="Quantity">
              <InputNumber />
            </Form.Item>
            <Form.Item name="reject_quantity" label="Reject Quantity">
              <InputNumber />
            </Form.Item>
            <Form.Item name="reject_reason" label="Reject Reason">
              <TextArea />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </Modal>
  );
};

export default DeliveriesForm;
