import { Menu, Layout } from "antd";
import {
  CarOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  ContactsOutlined,
  SettingOutlined,
  ProfileOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import React from "react";
import "antd/dist/antd.min.css";
// import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";
import AuthenticateService from "../services/AuthService";
import { useNavigate } from "react-router-dom";

const { SubMenu } = Menu;
const { Header } = Layout;

const Navbar = () => {
  const current = React.useState("mail");
  const navigate = useNavigate();
  const { userHasAuthenticated } = useAppContext();

  const handleClick = () => {
    console.log("ehere");
    AuthenticateService.logout();
    userHasAuthenticated(false);
    navigate("/");
  };

  return (
    <Header
      style={{
        background: "#fff",
        position: "fixed",
        zIndex: 100,
        width: "100%",
      }}
    >
      <div className="logo">
        <Link to="/">
          {/* <img src={logo} alt="logo" width={30} height={30} /> */}
        </Link>
      </div>
      <Menu
        theme="light"
        selectedKeys={[current]}
        mode="horizontal"
        inlineIndent={0}
      >
        <Menu.Item key="vehicles">
          <Link to="/Vehicles">
            <CarOutlined style={{ paddingRight: "5px" }} />
            Vehicles
          </Link>
        </Menu.Item>
        <Menu.Item key="map">
          <Link to="/Map">
            <EnvironmentOutlined style={{ paddingRight: "5px" }} />
            Map
          </Link>
        </Menu.Item>
        <SubMenu
          key="Jobs"
          title={
            <span>
              <FileTextOutlined style={{ paddingRight: "5px" }} />
              Jobs
            </span>
          }
        >
          <Menu.Item key="Deliveries">
            <Link to="/Jobs/Deliveries">Deliveries</Link>
          </Menu.Item>
          <Menu.Item key="Calender">
            <Link to="/Jobs/Calendar">Calendar</Link>
          </Menu.Item>
          <Menu.Item key="AssignedJob">
            <Link to="/Jobs/AssignedJob">Assigned job</Link>
          </Menu.Item>
          {/* <Menu.Item key="SearchJobs">Search Jobs</Menu.Item>
          <Menu.Item key="ImportJobs">Import Jobs</Menu.Item>
          <Menu.Item key="BulkUpdate">Bulk Update</Menu.Item>
          <Menu.Item key="BulkSearch">Bulk Search</Menu.Item>
          <Menu.Item key="CODSearch">COD Search</Menu.Item> */}
        </SubMenu>
        {/* <Menu.Item key="contacts">
          <ContactsOutlined style={{ paddingRight: "5px" }} />
          Contacts
        </Menu.Item> */}
        <SubMenu
          key="reports"
          title={
            <span>
              <LineChartOutlined style={{ paddingRight: "5px" }} />
              Report
              {/* <DownOutlined /> */}
            </span>
          }
        >
          {/* <Menu.Item key="DailyJob">
            <Link to="/Reports/DailyJob">Daily job</Link>
          </Menu.Item> */}
        </SubMenu>
        <SubMenu
          key="Settings"
          title={
            <span>
              <SettingOutlined style={{ paddingRight: "5px" }} />
              Settings
              {/* <DownOutlined /> */}
            </span>
          }
        >
          <Menu.Item key="Group">
            <Link to="/Settings/Group">Group</Link>
          </Menu.Item>
          <Menu.Item key="Reason">
            <Link to="/Settings/Reason">Reason</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Accounts"
          style={{ marginLeft: "auto" }}
          title={
            <span>
              <ProfileOutlined style={{ paddingRight: "5px" }} />
              Accounts
              {/* <DownOutlined /> */}
            </span>
          }
        >
          <Menu.Item key="profile">
            <Link to="/UserProfile">My profile</Link>
          </Menu.Item>
          <Menu.Item key="Logout">
            {/* <Link to="/Logout">Logout</Link> */}
            <a href="/" onClick={handleClick}>
              Logout
            </a>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default Navbar;
