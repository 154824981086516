import React from "react";
import { Card, Descriptions, Timeline } from "antd";
import dayjs from "dayjs";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const Tracker = () => {
  return (
    <>
      <div className="site-layout-content">
        <Descriptions
          title="Tracking information"
          style={{
            paddingLeft: "24px",
            backgroundColor: "whitesmoke",
            marginBottom: "5px",
          }}
        >
          <Descriptions.Item label="Tracking No.">
            SZDVVVPOSHJSJD001
          </Descriptions.Item>
          <Descriptions.Item label="Service">Delivery</Descriptions.Item>
          <Descriptions.Item label="Deliver To">
            Hangzhou, Zhejiang
          </Descriptions.Item>
          <Descriptions.Item label="Status">empty</Descriptions.Item>
          <Descriptions.Item label="Date">
            {dayjs().format("YYYY-MM-DD")}
          </Descriptions.Item>
        </Descriptions>
        <Card
          title="Location"
          cover={
            <MapContainer
              style={{ height: "50vh", width: "95%" }}
              center={[3.116883545103372, 101.57717720369551]}
              zoom={13}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[3.116883545103372, 101.57717720369551]}>
                <Popup>I am Jack</Popup>
              </Marker>
              <Marker position={[3.121497092304506, 101.58513104972022]}>
                <Popup>I am Rose</Popup>
              </Marker>
            </MapContainer>
          }
        ></Card>
        <Timeline
          mode="left"
          pending="  "
          style={{
            marginTop: "10px",
            paddingTop: "10px",
            backgroundColor: "whitesmoke",
          }}
        >
          <lable
            style={{
              paddingLeft: "24px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Timeline
          </lable>
          <Timeline.Item label="2015-09-01">Create a services</Timeline.Item>
          <Timeline.Item label="2015-09-01 09:12:11">
            Solve initial network problems
          </Timeline.Item>
          <Timeline.Item>Technical testing</Timeline.Item>
          <Timeline.Item label="2015-09-01 09:12:11">
            Network problems being solved
          </Timeline.Item>
        </Timeline>
      </div>
    </>
  );
};

export default Tracker;
