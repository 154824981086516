import http from "../http-common";
const create = (data) => {
  return http.post("/jobStatusTrace", data);
};
const getAllByJobId = (jobId) => {
  return http.get(`/jobStatusTrace/${jobId}`);
};

const jobStatusTraceObj = {
  create,
  getAllByJobId,
};
export default jobStatusTraceObj;
