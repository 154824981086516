import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import SettingService from "../services/SettingService";

const { Option } = Select;
const TableFilterForm = ({ fields, fieldsLabel, values, onSearch }) => {
  const [expand, setExpand] = useState(false);
  const [groupsData, setGroupsData] = useState();
  const [isExpandVisible, setExpandVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: values["name"],
      driver_id: values["driver_id"],
      groups: values["groups"],
      zones: values["zones"],
    });
    if (!groupsData) {
      SettingService.getAllByEntity("group")
        .then((response) => {
          setGroupsData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [form, values]);

  const getFields = () => {
    const children = [];
    var count;
    if (fields.length > 6) {
      if (!isExpandVisible) {
        setExpandVisible(true);
      }
    }
    if (!expand && fields.length > 6) {
      count = 6;
    } else {
      count = fields.length;
    }

    for (let i = 0; i < count; i++) {
      console.log("fields: ", fields[i]);
      children.push(
        <Col style={{ marginBottom: "-15px" }} span={8} key={i}>
          <Form.Item name={fields[i]} label={fieldsLabel[i]}>
            {fields[i] === "groups" ? (
              <Select
                placeholder="Select group"
                // onChange={(value, element) => {
                //   setSelectedVehicle(value);
                //   // setSelectedVehicle(element.key);
                // }}
                allowClear
              >
                {groupsData ? (
                  groupsData.map((option) => (
                    <Option key={option.id} value={option.name}>
                      {option.name}
                    </Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
      );
    }

    return children;
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      // className="ant-advanced-search-form"
      onFinish={onSearch}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          {isExpandVisible ? (
            <a
              style={{
                fontSize: 12,
              }}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? (
                <>
                  <UpOutlined />
                  Collapse
                </>
              ) : (
                <>
                  <DownOutlined />
                  Expand
                </>
              )}
            </a>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default TableFilterForm;
