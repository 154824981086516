import "./App.css";
import React, { useState } from "react";
import { Layout } from "antd";

import UserProfile from "./pages/account/UserProfile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Map from "./pages/Map";
import Vehicles from "./pages/vehicles/Vehicles";
import Deliveries from "./pages/jobs/Deliveries";
import Calendar from "./pages/jobs/Calendar";
import Tracker from "./pages/jobs/Tracker";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar";
import BreadCrumb from "./components/BreadCrumb";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppContext, TrackerContext } from "./utils/AppContext";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthenticateService from "./services/AuthService";
import AssignedJob from "./pages/jobs/AssignedJob";
import DailyJob from "./pages/reports/DailyJob";
import GroupSetting from "./pages/settings/Group";
import ReasonSetting from "./pages/settings/Reason";

const { Header, Content, Footer } = Layout;
function App() {
  let authenticated = false;
  let tracker = false;
  const user = AuthenticateService.getCurrentUser();

  if (user && !user.is_new) {
    authenticated = true;
  }

  if (window.location.pathname === "/t") {
    tracker = true;
  }
  const [isAuthenticated, userHasAuthenticated] = useState(authenticated);
  const [isTrackerLink, setTrackerLink] = useState(tracker);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <TrackerContext.Provider value={{ isTrackerLink, setTrackerLink }}>
        <Router>
          {isAuthenticated && !isTrackerLink ? (
            <>
              <Navbar />
              <BreadCrumb />
            </>
          ) : (
            <Header
              style={{
                background: "#fff",
              }}
            >
              <div className="logo">
                {/* <img src={logo} alt="logo" width={30} height={30} /> */}
              </div>
            </Header>
          )}
          <Layout>
            <Content style={{ minHeight: "87vh" }}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<ProtectedRoute Component={Dashboard} />}
                />
                <Route
                  exact
                  path="/t"
                  element={<ProtectedRoute Component={Tracker} />}
                />
                <Route
                  exact
                  path="/Vehicles"
                  element={<ProtectedRoute Component={Vehicles} />}
                />
                <Route
                  exact
                  path="/Map"
                  element={<ProtectedRoute Component={Map} />}
                />
                <Route
                  exact
                  path="/Jobs/Deliveries"
                  element={<ProtectedRoute Component={Deliveries} />}
                />
                <Route
                  exact
                  path="/Jobs/Calendar"
                  element={<ProtectedRoute Component={Calendar} />}
                />
                <Route
                  exact
                  path="/Jobs/AssignedJob"
                  element={<ProtectedRoute Component={AssignedJob} />}
                />
                <Route
                  exact
                  path="/Reports/DailyJob"
                  element={<ProtectedRoute Component={DailyJob} />}
                />
                <Route
                  exact
                  path="/Settings/Group"
                  element={<ProtectedRoute Component={GroupSetting} />}
                />
                <Route
                  exact
                  path="/Settings/Reason"
                  element={<ProtectedRoute Component={ReasonSetting} />}
                />
                <Route
                  exact
                  path="/UserProfile"
                  element={<ProtectedRoute Component={UserProfile} />}
                />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Register" element={<Register />} />
              </Routes>
            </Content>
          </Layout>
          <Footer
            style={{ textAlign: "center", backgroundColor: "whitesmoke" }}
          >
            Design ©2021 Created by me
          </Footer>
        </Router>
      </TrackerContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
