import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext, useTrackerContext } from "../utils/AppContext";

const ProtectedRoute = ({ Component }) => {
  const { isAuthenticated } = useAppContext();
  const { isTrackerLink } = useTrackerContext();

  return isAuthenticated || isTrackerLink ? (
    <Component />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
