import http from "../http-common";
const getAll = () => {
  return http.get("/jobs");
};
const getAllByDate = (month, year) => {
  return http.get(`/jobs/findByDate?month=${month}&year=${year}`);
};
const getAllByMonth = (year) => {
  return http.get(`/jobs/findByMonth?year=${year}`);
};
const getAssignedJob = () => {
  return http.get("/jobs/assignedJob");
};
const get = (id) => {
  return http.get(`/jobs/${id}`);
};
const create = (data) => {
  return http.post("/jobs", data);
};
const uploadExcel = (data) => {
  return http.post("/jobs/upload", data);
};
const updateVehicle = (data) => {
  return http.put("/jobs/updateVehicle", data);
};
const updateGroup = (data) => {
  return http.put("/jobs/updateGroup", data);
};
const updateDate = (data) => {
  return http.put("/jobs/updateDate", data);
};
const update = (id, data) => {
  return http.put(`/jobs/${id}`, data);
};
const remove = (id) => {
  return http.delete(`/jobs/${id}`);
};
const removeAll = () => {
  return http.delete(`/jobs`);
};
const findByName = (job) => {
  var queryString = `/jobs?`;
  if (job.batch_num) {
    queryString += `&batch_num=${job.batch_num}`;
  }
  if (job.id) {
    queryString += `&id=${job.id}`;
  }
  if (job.postal_code) {
    queryString += `&postal_code=${job.postal_code}`;
  }
  if (job.group) {
    queryString += `&group=${job.group}`;
  }
  if (job.job_status) {
    queryString += `&job_status=${job.job_status}`;
  }
  if (job.address) {
    queryString += `&address=${job.address}`;
  }
  if (job.contact) {
    queryString += `&contact=${job.contact}`;
  }
  if (job.from_date) {
    queryString += `&from_date=${job.from_date}`;
  }
  if (job.to_date) {
    queryString += `&to_date=${job.to_date}`;
  }
  if (job.pts) {
    queryString += `&pts=${job.pts}`;
  }
  if (job.quantity) {
    queryString += `&quantity=${job.quantity}`;
  }
  if (job.marking_number) {
    queryString += `&marking_number=${job.marking_number}`;
  }
  if (job.assign_to) {
    queryString += `&assign_to=${job.assign_to}`;
  }
  if (job.instructions) {
    queryString += `&instructions=${job.instructions}`;
  }
  if (job.reasons) {
    queryString += `&reasons=${job.reasons}`;
  }
  if (job.received_by) {
    queryString += `&received_by=${job.received_by}`;
  }
  if (job.pod_time) {
    queryString += `&pod_time=${job.pod_time}`;
  }
  if (job.recipient) {
    queryString += `&recipient=${job.recipient}`;
  }
  // if (vehicle.SpeedStatuses) {
  //   queryString += `&speedStatuses=${vehicle.SpeedStatuses}`;
  // }
  return http.get(queryString);
};

const getPhotos = (data) => {
  return http.get(`/jobs/jobPhotos/${data}`);
};

const getDashboard = (organization_id) => {
  return http.get(`/jobs/dashboard/${organization_id}`);
};

const getPodPdf = (job_uuid) => {
  return http.get(`/jobs/podpdf/${job_uuid}`, { responseType: "arraybuffer" });
};

const jobObj = {
  getAll,
  getAllByDate,
  getAllByMonth,
  getAssignedJob,
  get,
  create,
  uploadExcel,
  updateVehicle,
  updateGroup,
  updateDate,
  update,
  remove,
  removeAll,
  findByName,
  getPhotos,
  getDashboard,
  getPodPdf,
};

export default jobObj;
