import http, { customAxios } from "../http-common";

const getCoords = () => {
  return http.get("/map/");
};

const mbGeocoding = (search_places) => {
  const mbToken =
    "pk.eyJ1Ijoiam9objAwMCIsImEiOiJjbDJsN2gyMWEwYXZlM29wamM4YThlY2Z2In0.cCRv8A3lL0WVVKEE45fKMQ";
  return customAxios.get(`${search_places}.json?access_token=${mbToken}`);
};

const mapObj = {
  getCoords,
  mbGeocoding,
};

export default mapObj;
