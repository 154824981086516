import { useEffect, useState } from "react";
import { Badge } from "antd";
import Calendar from "../../components/Calendar";

import JobService from "../../services/JobService";
import dayjs from "dayjs";

const jobStatusMap = {
  0: {
    text: "Info Received",
  },
  1: {
    text: "In Transit",
  },
  2: {
    text: "Dispatched",
  },
  3: {
    text: "Assigned",
  },
  4: {
    text: "Out For Delivery / Out For Collection",
  },
  5: {
    text: "Completed",
  },
  6: {
    text: "Partially Completed",
  },
  7: {
    text: "Failed",
  },
  8: {
    text: "On Hold",
  },
  9: {
    text: "Return",
  },
};

const DeliveriesCalendar = () => {
  const [data, setData] = useState(null);
  const [monthData, setMonthData] = useState(null);
  useEffect(() => {
    if (data === null) {
      console.log("useeffect1: ", data);
      JobService.getAllByDate(
        dayjs().format("MM"),
        dayjs().format("YYYY")
      ).then((response) => {
        setData(response.data);
      });
    }
    if (monthData === null) {
      JobService.getAllByMonth(dayjs().format("YYYY")).then((response) => {
        console.log("getallbymonth");
        setMonthData(response.data);
      });
    }
    console.log("useeffect: ", data);
  }, [data, monthData]);

  let found = null;
  let foundId = 0;
  function getListData(mydata, value) {
    let listData = [];
    // console.log("getlist", mydata, value);
    if (mydata.length > 0) {
      found = mydata.filter(
        (element) =>
          dayjs(element.date).format("YYYY-MM-DD") ===
          dayjs(value).format("YYYY-MM-DD")
      );
      if (found) {
        // console.log("found: ", found);
        for (let i = 0; i < found.length; i++) {
          listData.push({
            id: foundId,
            url:
              "/jobs/deliveries?job_status=" +
              found[i].job_status +
              "&date=" +
              dayjs(value).format("YYYY-MM-DD"),
            color: "purple",
            count: found[i].count,
            content: jobStatusMap[found[i].job_status].text,
          });
          foundId++;
        }
      }
    }

    return listData || [];
  }

  function dateCellRender(mydata, value) {
    const listData = getListData(mydata, value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.id}>
            <a href={item.url} style={{ cursor: "pointer" }}>
              <Badge
                count={item.count}
                style={{ backgroundColor: item.color }}
              />
              <label
                style={{
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "70%",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                  verticalAlign: "text-bottom",
                }}
              >
                <span
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  {item.content}
                </span>
              </label>
            </a>
          </li>
        ))}
      </ul>
    );
  }
  let filteredData;
  let filteredDataId = 0;
  function getMonthData(value) {
    let listData = [];
    if (monthData.length > 0) {
      filteredData = monthData.filter(
        (element) =>
          dayjs(element.date).format("YYYY-MM") ===
          dayjs(value).format("YYYY-MM")
      );
      if (filteredData) {
        for (let i = 0; i < filteredData.length; i++) {
          listData.push({
            id: filteredDataId,
            url: "/jobs/deliveries?job_status=" + filteredData[i].job_status,
            color: "purple",
            count: filteredData[i].count,
            content: jobStatusMap[filteredData[i].job_status].text,
          });
          filteredDataId++;
        }
      }
    }

    return listData || [];
  }

  function monthCellRender(value) {
    const listData = getMonthData(value);
    // console.log("monthcelldata:", num);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.id}>
            <a href={item.url} style={{ cursor: "pointer" }}>
              <Badge
                count={item.count}
                style={{ backgroundColor: item.color }}
              />
              <label
                style={{
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "70%",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                  verticalAlign: "text-bottom",
                }}
              >
                <span
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  {item.content}
                </span>
              </label>
            </a>
          </li>
        ))}
      </ul>
    );
  }

  function onPanelChange(value) {
    console.log("onpanelchange: ", value);
    JobService.getAllByDate(
      dayjs(value).format("MM"),
      dayjs(value).format("YYYY")
    ).then((response) => {
      setData(response.data);
    });
  }
  function onSelect(value) {
    console.log("onSelect: ", dayjs().format("YYYY-MM-DD"));
  }
  return (
    <>
      {data != null ? (
        <Calendar
          dateCellRender={(value) => dateCellRender(data, value)}
          monthCellRender={monthCellRender}
          onPanelChange={onPanelChange}
          // onSelect={onSelect}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DeliveriesCalendar;
// ReactDOM.render(
//   <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />,
//   mountNode,
// );
