import React from "react";
import { Menu } from "antd";

const TopicMenu = ({ topics, selectedKey, changeSelectedKey }) => {
  const styledTopics = [];
  topics.forEach((topic, index) =>
    styledTopics.push(
      <Menu.Item key={index} onClick={changeSelectedKey}>
        {topic.icon}
        <span style={{ marginRight: "2px" }}>{topic.name}</span>
      </Menu.Item>
    )
  );

  return (
    <Menu
      mode="inline"
      style={{
        minHeight: "85vh",
        // backgroundColor: "#f0f0f0",
      }}
      selectedKeys={[selectedKey]}
    >
      {styledTopics}
    </Menu>
  );
};
export default TopicMenu;
