import React, { useEffect, useState } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMap,
} from "react-leaflet";
// import RoutingMachine from "../RoutingMachine";
import MapService from "../services/MapService";

const Map = () => {
  // const mapRef = React.useRef();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [polyLine, setPolyLine] = useState([[]]);

  useEffect(() => {
    console.log("map11");
    MapService.getCoords().then((response) => {
      var points = [];
      // console.log("lllat", response.data);
      for (let [key, { latitude, longitude }] of Object.entries(
        response.data
      )) {
        // console.log(`${latitude}: ${longitude}`);
        var point = [latitude, longitude];
        points.push(point);
      }
      console.log(points);
      // points = points.slice(0, 3);
      // setPolyLine([
      //   [3.1165787, 101.5773296],
      //   [3.1166345, 101.577347],
      //   [3.1164979, 101.5772781],
      // ]);
      setPolyLine(points);
    });
  }, []);

  const limeOptions = { color: "red" };
  // const polyline = [[3.116883545103372, 101.57717720369551]];
  console.log("sad", polyLine);
  return (
    <>
      <MapContainer
        // ref={mapRef}
        center={[3.116883545103372, 101.57717720369551]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[3.116883545103372, 101.57717720369551]}>
          <Popup>I am Jack</Popup>
        </Marker>
        <Marker position={[3.121497092304506, 101.58513104972022]}>
          <Popup>I am Rose</Popup>
        </Marker>

        {/* <RoutingMachine /> */}
        {console.log("ccc", polyLine)}
        <Polyline pathOptions={limeOptions} positions={polyLine} />
        {/* {data ? (
          data.map(({ id, from_lat, from_long, to_lat, to_long }) => {
            return (
              <Polyline
                key={id}
                positions={[
                  [from_lat, from_long],
                  [to_lat, to_long],
                ]}
                color={"red"}
              />
            );
          })
        ) : (
          <></>
        )} */}
      </MapContainer>
    </>
  );
};

export default Map;
