import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";

import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Steps,
  Select,
} from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import AuthenticateService from "../services/AuthService";
import loginBackgroundImage from "../../src/email-location-tracking-1.jpg";

const { Step } = Steps;
const { Option } = Select;
const steps = [
  {
    title: "Personal info",
  },
  {
    title: "Company info",
  },
];
const Register = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [current, setCurrent] = useState(0);
  const [personalInfo, setPersonalInfo] = useState();
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (isAuthenticated) {
      //   history.push("/Dashboard");
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  async function onFinishStep1(values) {
    console.log("step1", values);
    setPersonalInfo(values);
    setCurrent(1);
  }

  async function onFinishStep2(values) {
    console.log("step2", values);
    let registrationInfo = {
      name: personalInfo.name,
      email: personalInfo.email,
      password: personalInfo.password,
      organization: values.organization,
      country: values.country,
      fleet_size: values.fleet_size,
      industry: values.industry,
    };
    AuthenticateService.register(registrationInfo)
      .then((resp) => {
        message.success(
          "Thank you for registering! You can now login with your new account"
        );
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${loginBackgroundImage})`,
        height: "89vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        // className="site-layout-content"
        style={{
          paddingTop: "15vh",
          // paddingLeft: "70vh",
          // paddingRight: "70vh",
        }}
      >
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12, offset: 6 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <Steps
              style={{ backgroundColor: "rgba(192,192,192,0.5)" }}
              type="navigation"
              size="small"
              current={current}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            {current === 0 ? (
              <Form
                style={{
                  paddingTop: "80px",
                  paddingBottom: "40px",
                  backgroundColor: "rgba(192,192,192,0.5)",
                }}
                name="normal_login"
                className="login-form"
                // labelCol={{ span: 8 }}
                wrapperCol={{
                  sm: { span: 20, offset: 2 },
                  md: { span: 20, offset: 2 },
                  lg: { span: 20, offset: 2 },
                  xl: { span: 13, offset: 5 },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishStep1}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                  ]}
                >
                  <Input
                    wrapperCol={{ span: 4, offset: 10 }}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    wrapperCol={{ span: 4, offset: 10 }}
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please accept Terms of Use and Privacy Policy."
                              )
                            ),
                    },
                  ]}
                >
                  <Checkbox>
                    I understand & accept the <a href="">terms</a> and{" "}
                    <a href="">privacy policy</a>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Sign up now!
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                style={{
                  paddingTop: "80px",
                  paddingBottom: "40px",
                  backgroundColor: "rgba(192,192,192,0.5)",
                }}
                name="normal_login"
                className="login-form"
                // labelCol={{ span: 8 }}
                wrapperCol={{
                  sm: { span: 20, offset: 2 },
                  md: { span: 20, offset: 2 },
                  lg: { span: 20, offset: 2 },
                  xl: { span: 13, offset: 5 },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishStep2}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="organization"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Organization!",
                    },
                  ]}
                >
                  <Input
                    wrapperCol={{ span: 4, offset: 10 }}
                    placeholder="Organization"
                  />
                </Form.Item>
                <Form.Item
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Country!",
                    },
                  ]}
                >
                  <Select placeholder="Country">
                    <Option value="0">Singapore</Option>
                    <Option value="1">Malaysia</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="fleet_size"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Fleet Size!",
                    },
                  ]}
                >
                  <Select placeholder="Fleet size">
                    <Option value="0">0 - 1</Option>
                    <Option value="1">2 - 5</Option>
                    <Option value="2">5 - 10</Option>
                    <Option value="3">11 - 25</Option>
                    <Option value="4"> &gt; 25</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Industry!",
                    },
                  ]}
                >
                  <Select placeholder="Industry">
                    <Option value="0">3rd party logistics</Option>
                    <Option value="1">Retail with own fleet</Option>
                    <Option value="2">Retail using outsourced fleet</Option>
                    <Option value="3">Construction supplies</Option>
                    <Option value="4">Food & Beverages</Option>
                    <Option value="5">Distributor</Option>
                    <Option value="6">Furniture & home appliances</Option>
                    <Option value="7">Cold chain logistics</Option>
                    <Option value="8">
                      Medical / Pharmaceutical logistics
                    </Option>
                    <Option value="9">Print & events</Option>
                    <Option value="10">Software / IT vendor</Option>
                    <Option value="11">Field service</Option>
                    <Option value="12">Waste management / Recycling</Option>
                    <Option value="13">Oil & gas</Option>
                    <Option value="14">Laundry</Option>
                    <Option value="15">Others</Option>
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Register;
