import Enum from "./Enum";

export const JobStatusEnum = new Enum(
  "Info Received",
  "In Transit",
  "Dispatched",
  "Assigned",
  "Out For Delivery",
  "Completed",
  "Partially Completed",
  "Failed",
  "On Hold",
  "Return"
);
