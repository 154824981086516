import axios from "axios";
import AuthService from "../src/services/AuthService";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: "https://api.lokalproject.com/api", //process.env.REACT_APP_API_PATH
    // baseURL: "http://localhost:5000/api",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(
    (config) => {
      // const token = localStorage.getItem('token');
      const user = localStorage.getItem("user");

      config.headers["x-access-token"] = user; // ? user.accessToken : "";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        console.log("err.response", err.response);
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await AuthService.refreshToken();
            const { accessToken } = rs.data;
            console.log("iresp", accessToken);
            // localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("user", accessToken);
            instance.defaults.headers.common["x-access-token"] = accessToken;
            return instance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 403 && err.response.data) {
          AuthService.logout();
          window.location.replace("/");
          return Promise.reject(err.response.data);
        }
      }
      return Promise.reject(err);
    }
  );

  return instance;
};

export const customAxios = axios.create({
  baseURL: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
});

export default fetchClient();
