import { useContext, createContext } from "react";

export const AppContext = createContext(null);
export const TrackerContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export function useTrackerContext() {
  return useContext(TrackerContext);
}
