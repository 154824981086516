import http from "../http-common";
const getAll = () => {
  return http.get("/vehicles");
};
const get = (id) => {
  return http.get(`/vehicles/${id}`);
};
const create = (data) => {
  return http.post("/vehicles", data);
};
const update = (id, data) => {
  return http.put(`/vehicles/${id}`, data);
};
const remove = (id) => {
  return http.delete(`/vehicles/${id}`);
};
const removeAll = () => {
  return http.delete(`/vehicles`);
};
const findByName = (vehicle) => {
  var queryString = `/vehicles?`;
  if (vehicle.name) {
    queryString += `&name=${vehicle.name}`;
  }
  if (vehicle.driver_id) {
    queryString += `&driver_id=${vehicle.driver_id}`;
  }
  if (vehicle.groups) {
    queryString += `&groups=${vehicle.groups}`;
  }
  if (vehicle.zones) {
    queryString += `&zones=${vehicle.zones}`;
  }
  if (vehicle.vehicle_no) {
    queryString += `&vehicle_no=${vehicle.vehicle_no}`;
  }
  if (vehicle.phone_no) {
    queryString += `&phone_no=${vehicle.phone_no}`;
  }
  // if (vehicle.SpeedStatuses) {
  //   queryString += `&speedStatuses=${vehicle.SpeedStatuses}`;
  // }
  return http.get(queryString);
};

const vehicleObj = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
};

export default vehicleObj;
