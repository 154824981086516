import { Transfer, Table, Select } from "antd";
import difference from "lodash/difference";
import { useEffect, useState } from "react";
import JobService from "../services/JobService";
import VehicleService from "../services/VehicleService";

const { Option } = Select;
// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      // disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection = {
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            // style={{ pointerEvents: listDisabled ? "none" : null }}
            onRow={({ key }) => ({
              onClick: () => {
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        </>
      );
    }}
  </Transfer>
);

const tableColumns = [
  {
    dataIndex: "batch_num",
    title: "Batch Number",
  },
  {
    dataIndex: "address",
    title: "Address",
  },
];

const TransferBox = ({
  fromVehicleJobIdData,
  setFromVehicleJobIdData,
  toVehicleJobIdData,
  setToVehicleJobIdData,
  oriFromVehicleJobIdData,
  setOriFromVehicleJobIdData,
  oriToVehicleJobIdData,
  setOriToVehicleJobIdData,
  fromVehicle,
  setFromVehicle,
  toVehicle,
  setToVehicle,
}) => {
  const [fromVehicleJobData, setFromVehicleJobData] = useState([]);
  const [toVehicleJobData, setToVehicleJobData] = useState([]);
  const [fromVehicleData, setFromVehicleData] = useState([]);
  const [toVehicleData, setToVehicleData] = useState([]);

  useEffect(() => {
    VehicleService.getAll()
      .then((response) => {
        var result = response.data.map((obj) => ({ ...obj, disabled: false }));
        setFromVehicleData(result);

        var result2 = response.data.map((obj) => ({ ...obj, disabled: false }));
        setToVehicleData(result2);
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    // console.log("targetKeys111", targetKeys, fromVehicleJobData);
    // setTargetKeys(nextTargetKeys);
    setToVehicleJobIdData(nextTargetKeys);
    console.log("nextTargetKeys", nextTargetKeys, direction, moveKeys);
    var filteredVehicles;
    if (direction === "right") {
      const toRemove = new Set(moveKeys);
      // console.log("toRemove", toRemove, fromVehicleJobIdData);
      filteredVehicles = fromVehicleJobIdData.filter((x) => !toRemove.has(x));

      console.log("filteredVehicles", filteredVehicles);
      setFromVehicleJobIdData(filteredVehicles);
    } else if (direction === "left") {
      moveKeys.forEach((key) => {
        fromVehicleJobIdData.push(key);
      });
    }
    // console.log("fromVehicleJobIdData", fromVehicleJobIdData);
  };

  const onFromVehicleChange = (value) => {
    let toVehicles = [...toVehicleData];
    toVehicles.forEach((toVehicle) => {
      if (toVehicle["vehicle_uuid"] === value) {
        toVehicle["disabled"] = true;
      } else {
        toVehicle["disabled"] = false;
      }
    });
    setToVehicleData(toVehicles);
    // console.log("toVehicleData", toVehicleData, fromVehicleData);
    setFromVehicle(value);
    const jobSearch = { assign_to: value };
    JobService.findByName(jobSearch).then((response) => {
      const newResult = response.data.map((r) => {
        return { ...r, key: r.id };
      });
      setFromVehicleJobData(newResult);
      var idResult = [...newResult].map((item) => item.id);
      setFromVehicleJobIdData(idResult);

      var oriIdResult = [...newResult].map((item) => item.id);
      setOriFromVehicleJobIdData(oriIdResult);
    });
  };

  const onToVehicleChange = (value) => {
    let fromVehicles = [...fromVehicleData];
    console.log("onToVehicleChange1", value);
    fromVehicles.forEach((fromVehicle) => {
      if (fromVehicle["vehicle_uuid"] === value) {
        fromVehicle["disabled"] = true;
      } else {
        fromVehicle["disabled"] = false;
      }
    });
    setFromVehicleData(fromVehicles);
    setToVehicle(value);
    const jobSearch = { assign_to: value };
    JobService.findByName(jobSearch).then((response) => {
      const newResult = response.data.map((r) => {
        return { ...r, key: r.id };
      });
      setToVehicleJobData(newResult);
      const idResult = [...newResult].map((item) => item.id);
      // setTargetKeys(originTargetKeys);
      setToVehicleJobIdData(idResult);

      const oriIdResult = [...newResult].map((item) => item.id);
      // setTargetKeys(originTargetKeys);
      setOriToVehicleJobIdData(oriIdResult);
    });
  };

  return (
    <>
      <Select
        style={{ width: "46.5%" }}
        placeholder="Transfer from"
        onChange={onFromVehicleChange}
      >
        {fromVehicleData.map((option, index) => (
          <Option
            key={index}
            value={option.vehicle_uuid}
            disabled={option.disabled}
          >
            {option.name}
          </Option>
        ))}
      </Select>

      <Select
        style={{ float: "right", width: "46.5%" }}
        placeholder="Transfer to"
        onChange={onToVehicleChange}
      >
        {toVehicleData.map((option, index) => (
          <Option
            key={index}
            value={option.vehicle_uuid}
            disabled={option.disabled}
          >
            {option.name}
          </Option>
        ))}
      </Select>
      <TableTransfer
        dataSource={[...fromVehicleJobData, ...toVehicleJobData]}
        targetKeys={toVehicleJobIdData}
        // disabled={disabled}
        // showSearch={showSearch}
        onChange={onChange}
        // filterOption={(inputValue, item) =>
        //   item.title.indexOf(inputValue) !== -1 ||
        //   item.tag.indexOf(inputValue) !== -1
        // }
        disabled={!fromVehicle || !toVehicle}
        operations={["  ", "  "]}
        leftColumns={tableColumns}
        rightColumns={tableColumns}
      />
    </>
  );
};

export default TransferBox;
