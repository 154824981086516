import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const BreadCrumb = () => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    const pageName = pathname.substring(pathname.lastIndexOf("/") + 1);

    const pathnames = pathname.split("/").filter((item) => item);
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    return (
      <div
        style={{
          paddingTop: "70px",
          marginBottom: "20px",
          paddingLeft: "24px",
          // position: "fixed",
          // zIndex: 100,
          // backgroundColor: "whitesmoke",
        }}
      >
        <Breadcrumb
          style={{
            marginBottom: "30px",
          }}
        >
          {pathnames.length > 0 ? (
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          )}
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Breadcrumb.Item>{capatilize(name)}</Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item>
                <Link to={`${routeTo}`}>{capatilize(name)}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            // paddingTop: "30px",
          }}
        >
          {pageName}
        </div>
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
