import React, { useEffect, useState } from "react";
import { Table, Input, Card, Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import SettingService from "../../services/SettingService";

const { confirm } = Modal;
const ReasonSetting = () => {
  const [data, setData] = useState();
  const [name, setName] = useState("");
  // const [selectedRowKeys, selectRows] = useState();

  useEffect(() => {
    if (!data) {
      SettingService.getAllByEntity("reason").then((resp) => {
        // setData(resp.data);
        const newResult = resp.data.map((r) => {
          return { ...r, key: r.id };
        });
        setData(newResult);
      });
    }
  }, [data]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 1800,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<DeleteOutlined />}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            showConfirm(record);
          }}
        ></Button>
      ),
    },
  ];

  function showConfirm(record) {
    confirm({
      title: "Are you sure to delete this item?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        SettingService.remove(record.id, "reason").then((response) => {
          // window.location.reload(true);
          SettingService.getAllByEntity("reason").then(
            (response) => {
              const newResult = response.data.map((r) => {
                return { ...r, key: r.id };
              });
              setData(newResult);
            },
            (error) => {
              setData(null);
            }
          );
        });
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  // const onSelectChange = (selectedRowKeys) => {
  //   selectRows(selectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const reasonInputOnChange = (e) => {
    setName(e.target.value);
  };
  const addReason = (e) => {
    var regEx = /^[0-9a-zA-Z ]+$/;
    if (name.match(regEx)) {
      var reason = { name: name, entity: "reason" };
      console.log("sfdsdsf", reason);
      SettingService.create(reason)
        .then((resp) => {
          const newResult = resp.data.map((r) => {
            return { ...r, key: r.id };
          });
          setData(newResult);
          // setData(resp.data);

          console.log(resp.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
      setName(null);
    } else {
      console.log("popopo1111", name);
    }
  };
  return (
    <div className="site-layout-content">
      <Card
        title={
          <Input
            placeholder="Enter reason"
            onChange={reasonInputOnChange}
            style={{ width: "95%" }}
            value={name}
          />
        }
        extra={
          <Button type="primary" onClick={addReason}>
            Add
          </Button>
        }
        // style={{ width: "40%" }}
      >
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          // rowSelection={rowSelection}
          pagination={{ showSizeChanger: true }}
          // onRow={(r) => {
          //   return {
          //     onClick: (e) => {
          //       setVisible(true);
          //       setVehicleId(r.key);
          //       setFormTitle("Update vehicle");
          //       //   console.log("key", r.key);
          //     },
          //     style: { cursor: "pointer" },
          //   };
          // }}
        ></Table>
      </Card>
    </div>
  );
};

export default ReasonSetting;
