import React, { useState } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";

const { Dragger } = Upload;

const Uploader = (service) => {
  console.log("service:", typeof service.service.uploadExcel);
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const config = {
      headers: { "content-type": "multipart/form-data" },
      // onUploadProgress: (event) => {
      //   const percent = Math.floor((event.loaded / event.total) * 100);
      //   setProgress(percent);
      //   if (percent === 100) {
      //     setTimeout(() => setProgress(0), 1000);
      //   }
      //   onProgress({ percent: (event.loaded / event.total) * 100 });
      // },
    };
    try {
      const fmData = new FormData();
      fmData.append("file", file);
      const res = await axios
        .post("https://api.lokalproject.com/api/jobs/upload", fmData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("qqqqq", res);
          onSuccess(res.statusText);
        });
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const props = {
    // name: "file",
    accept: ".xlsx",
    customRequest(info) {
      uploadFile(info);
    },
    // action: "http://localhost:8080/api/jobs/importExcel",
    onChange(info) {
      console.log("info:", info);
      // const { status } = info.file;
      // if (status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      // if (status === "done") {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
      switch (info.file.status) {
        case "uploading":
          break;
        case "done":
          setSelectedFile(info.file);
          break;
        default:
          setSelectedFile(null);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for a single.</p>
    </Dragger>
  );
};
export default Uploader;
