import {
  Button,
  Space,
  Table,
  Tag,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Spin,
  Select,
  DatePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  SettingOutlined,
  SyncOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import JobStatusTraceService from "../../services/JobStatusTraceService";
import JobService from "../../services/JobService";
import MapService from "../../services/MapService";
import VehicleService from "../../services/VehicleService";
import DeliveriesForm from "./UpdateDeliveries";
import CSVLink from "../../components/excel/CSVLink";
import Uploader from "../../components/Uploader";
import TableFilterForm from "../../components/jobComponent/TableFilterForm";
import { isNullOrEmpty } from "../../utils/helper";
import dayjs from "dayjs";
import { JobStatusEnum } from "../../enumClass/JobStatus";
import SettingService from "../../services/SettingService";
import ItemService from "../../services/ItemService";

const { CheckableTag } = Tag;
const { Option } = Select;
const Deliveries = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState(true);
  const page = "deliveries";
  const [formTitle, setFormTitle] = useState("");
  const [isHidden, setIsHidden] = useState();
  const [selectedRowKeys, selectRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);
  const [groupsData, setGroupsData] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isAssignVehicleVisible, setIsAssignVehicleVisible] = useState(false);
  const [isAssignGroupVisible, setIsAssignGroupVisible] = useState(false);
  const [isChangeDateVisible, setIsChangeDateVisible] = useState(false);
  const [isUseTag, setIsUseTag] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [searchTagsValue, setSearchTagsValue] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [selectedTags, setSelectedTags] = useState([
    // "id",
    "batch_num",
    "postal_code",
    "contact",
    "date",
    "recipient",
    "pts",
    "quantity",
    "group",
    "assign_to",
    "job_status",
    "instructions",
    "marking_number",
    "pod_time",
    "reasons",
    "received_by",
  ]);
  const [selectedTagsLabel, setSelectedTagsLabel] = useState([
    // "ID",
    "Batch Number",
    "Postal Code",
    "Contact",
    "Date",
    "Recipient",
    "PTS",
    "Quantity",
    "Group",
    "Assign To",
    "Job Status",
    "Instructions",
    "Marking Number",
    "Pod Time",
    "Reasons",
    "Received By",
  ]);
  const filterFields = [
    // "id",
    "batch_num",
    "address",
    "postal_code",
    "contact",
    "date",
    "recipient",
    "pts",
    "quantity",
    "group",
    "assign_to",
    "job_status",
    "instructions",
    "marking_number",
    "pod_time",
    "reasons",
    "received_by",
  ];
  const filterFieldsLabel = {
    // id: "ID",
    batch_num: "Batch Number",
    address: "Address",
    postal_code: "Postal Code",
    contact: "Contact",
    date: "Date",
    recipient: "Recipient",
    pts: "PTS",
    quantity: "Quantity",
    group: "Group",
    assign_to: "Assign To",
    job_status: "Job Status",
    instructions: "Instructions",
    marking_number: "Marking Number",
    pod_time: "POD Time",
    reasons: "Reasons",
    received_by: "Received By",
  };

  useEffect(() => {
    if (searchParams.get("job_status")) {
      const jobFromCalendar = {
        job_status: searchParams.get("job_status"),
        date: [
          dayjs(searchParams.get("date")),
          dayjs(searchParams.get("date")),
        ],
      };
      // console.log("usereffttparams:", jobFromCalendar);
      onSearch(jobFromCalendar);
    } else {
      getJobs();
    }

    async function getJobs() {
      setLoading(true);
      await JobService.getAll().then((response) => {
        if (response.data) {
          const newResult = response.data.map((r) => {
            return { ...r, key: r.job_uuid };
          });
          setData(newResult);
        }
      });
      setLoading(false);
    }
    async function getGroups() {
      setLoading(true);
      await SettingService.getAllByEntity("group")
        .then((response) => {
          setGroupsData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }

    getGroups();
  }, []);

  const trackingStatusMap = {
    0: {
      text: "Info Received",
    },
    1: {
      text: "Info Received",
    },
    2: {
      text: "Info Received",
    },
    3: {
      text: "Assigned",
    },
    4: {
      text: "Out For Delivery / Out For Collection",
    },
    5: {
      text: "Delivered",
    },
    6: {
      text: "Partially Delivered",
    },
    7: {
      text: "Failed",
    },
    8: {
      text: "On Hold",
    },
    9: {
      text: "Return",
    },
  };

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   colOrder: 1,
    //   // width: "200px",
    //   sorter: (a, b) => a.id.localeCompare(b.id),
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Batch Number",
      dataIndex: "batch_num",
      key: "batch_num",
      colOrder: 2,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      colOrder: 3,
    },
    {
      title: "Postal Code",
      dataIndex: "postal_code",
      key: "postal_code",
      colOrder: 4,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      colOrder: 5,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      colOrder: 6,
      // width: "90px",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Recipient",
      dataIndex: "recipient",
      key: "recipient",
      colOrder: 7,
    },
    {
      title: "PTS",
      dataIndex: "pts",
      key: "pts",
      colOrder: 8,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      colOrder: 9,
      // width: "90px",
      sorter: (a, b) => a.quantity.localeCompare(b.quantity),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      colOrder: 10,
    },
    {
      title: "Assign To",
      dataIndex: "assign_to",
      key: "assign_to",
      colOrder: 11,
      // width: "120px",
      // render: (text, row) => row.vehicle.name,
    },
    {
      title: "Job Status",
      dataIndex: "job_status",
      key: "job_status",
      colOrder: 12,
      // width: "60px",
      render: (text, row) => JobStatusEnum[text],
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
      colOrder: 13,
      // width: "90px",
    },
    // {
    //   title: "Tracking Status",
    //   dataIndex: "job_status",
    //   key: "job_status",
    //   colOrder: 14,
    //   // width: "90px",
    //   // render: (text, row) => trackingStatusMap[text].text,
    // },
    {
      title: "Marking Number",
      dataIndex: "marking_number",
      key: "marking_number",
      colOrder: 15,
    },
    {
      title: "POD Time",
      dataIndex: "pod_time",
      key: "pod_time",
      colOrder: 16,
      // width: "30%",
      render: (pod_time) => {
        return dayjs(pod_time).isValid()
          ? dayjs(pod_time).format("YYYY-MM-DD HH:mm:ss")
          : null;
      },
      sorter: (a, b) => dayjs(a.pod_time).unix() - dayjs(b.pod_time).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reasons",
      dataIndex: "reasons",
      key: "reasons",
      colOrder: 17,
      // width: "30%",
    },
    {
      title: "Received By",
      dataIndex: "received_by",
      key: "received_by",
      colOrder: 18,
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys", selectedRowKeys);
    selectRows(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  async function onUpdate(id, job, items) {
    var coords = await MapService.mbGeocoding(encodeURIComponent(job.address))
      .then((response) => {
        const coords = response.data.features[0].geometry.coordinates;
        return coords;
      })
      .catch((err) => {
        console.log(err);
      });
    var jobWithCoords = {
      ...job,
      address_lat: coords[1],
      address_lng: coords[0],
    };
    console.log("asdsad", jobWithCoords);
    JobService.update(id, jobWithCoords)
      .then(async (response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.job_uuid };
        });
        setData(newResult);
        if (Array.isArray(items) && items.length) {
          const item = { job_uuid: id, item: items };
          console.log("dsad", item, items.length);
          await ItemService.create(item)
            .then((response) => {
              console.log(response.data);
            })
            .catch((err) => {
              console.log("iterm err", err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setJobId(null);
    setVisible(false);
  }

  async function onCreate(newJob, newItems) {
    JobService.create(newJob)
      .then((response) => {
        var newData = [...data];
        newData.push(response.data);
        const newResult = newData.map((r) => {
          return { ...r, key: r.job_uuid };
        });
        setData(newResult);
        console.log("createjob", response.data);
        if (Array.isArray(newItems) && newItems.length) {
          const item = { job_uuid: response.data.job_uuid, item: newItems };
          console.log("dsad", item, newItems.length);
          ItemService.create(item)
            .then((response) => {})
            .catch((err) => {
              console.log("iterm err", err);
            });
        }

        // const newStatusTrace = {
        //   jobId: response.data.id,
        //   status: response.data.job_status,
        //   datetime: dayjs(),
        //   driver: response.data.assign_to,
        //   proof: dayjs(),
        // };
        // JobStatusTraceService.create(newStatusTrace)
        //   .then((resp) => {
        //     console.log(resp.data);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((e) => {
        console.log(e);
      });

    setJobId(null);
    setVisible(false);
  }

  function onCancel(form) {
    form.resetFields();
    setJobId(null);
    setVisible(false);
  }
  const [popoverVisibility, setPopoverVisibility] = useState(false);

  function handleVisibleChange(visible) {
    setPopoverVisibility(visible);
  }

  const [checkedList, setCheckedList] = useState(columns);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const onChange = (list, e) => {
    console.log("checkedList11", checkedList);
    if (e.target.checked === false) {
      setCheckAll(false);
      setIndeterminate(checkedList.length - 1 > 0);
      setCheckedList(checkedList.filter((l) => l.key !== list.key));
    } else {
      setCheckAll(checkedList.length + 1 === columns.length);
      setIndeterminate(checkedList.length + 1 < columns.length);
      checkedList.push(list);
      setCheckedList([
        ...checkedList.sort((a, b) => {
          return a.colOrder - b.colOrder;
        }),
      ]);
    }
    console.log("checkedList", checkedList);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? columns : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onClickSync = () => {
    (async () => {
      //   setLoading(true);
      await JobService.getAll().then((response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.job_uuid };
        });
        setData(newResult);
        //   setLoading(false);
      });
    })();
  };

  function handleMenuClick(e) {
    if (selectedRowKeys.length > 0) {
      if (e.key === "2") {
        VehicleService.getAll()
          .then((resp) => {
            setVehicleData(resp.data);
          })
          .catch((err) => {
            console.log("vehicle data err:", err);
          });
        setIsAssignVehicleVisible(true);
      } else if (e.key === "3") {
        setIsAssignGroupVisible(true);
      } else if (e.key === "4") {
        setIsChangeDateVisible(true);
      }
    }
  }

  const actionsMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Delete</Menu.Item>
      <Menu.Item key="2">Assign Vehicles</Menu.Item>
      <Menu.Item key="3">Assign Group</Menu.Item>
      <Menu.Item key="4">Change Date</Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu onClick={handleMenuClick}>
      <CSVLink
        data={data}
        headers={columns}
        filename={"Deliveries_" + dayjs().format("YYYYMMDDHHmmss") + ".xlsx"}
      >
        <Menu.Item key="excel">As excel</Menu.Item>
      </CSVLink>
    </Menu>
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //#region Search panel
  // const genExtra = () => (
  //   <SettingOutlined
  //     onClick={(event) => {
  //       event.stopPropagation();
  //       // customizeFilterOptions();
  //       setIsFilterOptionsVisible(true);
  //     }}
  //   />
  // );
  //#endregion

  //#region Search Tags
  // const getFields = () => {
  //   const children = [];
  //   for (let i = 0; i < filterFields.length; i++) {
  //     children.push(
  //       <Col span={8} key={i}>
  //         <CheckableTag
  //           style={{ fontSize: "14px" }}
  //           key={filterFields[i]}
  //           checked={selectedTags.indexOf(filterFields[i]) > -1}
  //           onChange={(checked) =>
  //             handleChange(
  //               filterFields[i],
  //               filterFieldsLabel[filterFields[i]],
  //               checked
  //             )
  //           }
  //         >
  //           {filterFieldsLabel[filterFields[i]]}
  //         </CheckableTag>
  //       </Col>
  //     );
  //   }
  //   return children;
  // };

  function onRemoveSearchedTag(value) {
    Object.keys(searchTags).forEach(function (index) {
      if (index === value) {
        delete searchTags[value];
        delete searchFilters[value];
      }
    });

    if (Object.keys(searchTags).length === 0) {
      setIsUseTag(false);
    } else {
      setSearchTags(searchTags);
      setSearchFilters(searchFilters);
      setIsUseTag(true);
    }

    // (async () => {
    //   // setLoading(true);
    //   var result = await jobService.getJobs(searchFilters);
    //   const newResult = result.map((r) => {
    //     return { ...r, key: r.id };
    //   });
    //   setData(newResult);
    //   // setLoading(false);
    // })();
  }

  const onAddEditFilter = () => {
    console.log("onaddedit");
    setIsUseTag(false);
  };

  const onClearFilter = () => {
    setSearchTags([]);
    setSearchTagsValue({});
    setIsUseTag(false);

    (async () => {
      setLoading(true);
      await JobService.getAll().then((response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.job_uuid };
        });
        setData(newResult);
        setLoading(false);
      });
    })();
  };

  const searchCriteria = () => {
    let children = [];
    let searchTagsButton = {};

    Object.keys(searchTags).forEach(function (index) {
      children.push(
        <Tag closable onClose={() => onRemoveSearchedTag(index)}>
          {searchTags[index]}
        </Tag>
      );
    });

    searchTagsButton = (
      <div>
        {children}
        <Space>
          <Button size="small" type="primary" onClick={onAddEditFilter}>
            Add/Edit filter
          </Button>
          <Button size="small" type="primary" onClick={onClearFilter}>
            Clear
          </Button>
        </Space>
      </div>
    );
    return searchTagsButton;
  };

  //#region Search filter form
  function handleChange(tag, tagLabel, checked) {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    const nextSelectedTagsLabel = checked
      ? [...selectedTagsLabel, tagLabel]
      : selectedTagsLabel.filter((t) => t !== tagLabel);
    setSelectedTags(nextSelectedTags);
    setSelectedTagsLabel(nextSelectedTagsLabel);
  }

  const onSearch = (values) => {
    console.log("vvv: ", values);
    let searchedTags = {};
    let searchedFilters = {};
    for (let i = 0; i < selectedTags.length; i++) {
      if (!isNullOrEmpty(values[selectedTags[i]])) {
        searchedFilters[selectedTags[i]] = values[selectedTags[i]];
        if (selectedTags[i] === "job_status")
          searchedTags[selectedTags[i]] =
            filterFieldsLabel[selectedTags[i]] +
            " : " +
            JobStatusEnum[values[selectedTags[i]]];
        else if (selectedTags[i] === "date" || selectedTags[i] === "pod_time") {
          searchedTags[selectedTags[i]] =
            filterFieldsLabel[selectedTags[i]] +
            " : " +
            values[selectedTags[i]][0].format("YYYY-MM-DD") +
            " - " +
            values[selectedTags[i]][1].format("YYYY-MM-DD");
          searchedFilters[selectedTags[i]] =
            values[selectedTags[i]][0].format("YYYY-MM-DD") +
            " - " +
            values[selectedTags[i]][1].format("YYYY-MM-DD");
        } else
          searchedTags[selectedTags[i]] =
            filterFieldsLabel[selectedTags[i]] +
            " : " +
            values[selectedTags[i]];
      }
    }

    if (Object.keys(searchedTags).length > 0) {
      setSearchTags(searchedTags);
      setSearchFilters(searchedFilters);
      setSearchTagsValue(searchedFilters);
      setIsUseTag(true);
    }

    (async () => {
      //   setLoading(true);
      if (searchedFilters.date) {
        var fromDate = searchedFilters.date.substring(0, 10);
        var toDate = searchedFilters.date.substring(13);
        searchedFilters["from_date"] = fromDate;
        searchedFilters["to_date"] = toDate;
      }

      console.log("sds: ", searchedFilters);
      await JobService.findByName(searchedFilters).then((response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.id };
        });
        setData(newResult);
        //   setLoading(false);
      });
    })();
  };
  //#endregion

  return (
    <Spin spinning={loading}>
      <div className="site-layout-content" style={{ textAlign: "right" }}>
        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
          {isUseTag ? (
            searchCriteria()
          ) : (
            <Collapse defaultActiveKey={1}>
              <Collapse.Panel
                header="Search"
                key="1"
                // showArrow={false}
                // extra={genExtra()}
              >
                <TableFilterForm
                  page={page}
                  fields={selectedTags}
                  fieldsLabel={selectedTagsLabel}
                  values={searchTagsValue}
                  onSearch={onSearch}
                />
              </Collapse.Panel>
            </Collapse>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => {
              setVisible(true);
              setFormTitle("Add delivery");
              setIsHidden(true);
            }}
          >
            Add
          </Button>
          <Dropdown overlay={actionsMenu} trigger={["click"]}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              onClick={() => {}}
            >
              Actions
            </Button>
          </Dropdown>
          <Button
            type="primary"
            style={{ marginBottom: "10px" }}
            onClick={showModal}
          >
            Import
          </Button>
          <Modal
            title="Import deliveries"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
          >
            <Uploader service={JobService} />
          </Modal>
          <Dropdown overlay={exportMenu} trigger={["click"]}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              onClick={() => {}}
            >
              Export
            </Button>
          </Dropdown>
        </Space>
        {/* <SyncOutlined onClick={onClickSync} style={{ paddingLeft: "20px" }} /> */}
        <Table
          scroll={{ x: true }}
          style={{ verticalAlign: "top" }}
          columns={checkedList}
          dataSource={data}
          // loading={loading ? { indicator: <Spin indicator={loadingIcon} /> } : ""}
          onChange={onChange}
          // rowClassName={(record) => record.color.replace("#", "")}
          rowSelection={rowSelection}
          pagination={{ showSizeChanger: true }}
          onRow={(r) => {
            return {
              onClick: (e) => {
                setVisible(true);
                setFormTitle("Update delivery");
                setJobId(r.key);
              },
              style: { cursor: "pointer" },
            };
          }}
        ></Table>
        <DeliveriesForm
          visible={visible}
          jobId={jobId}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
          formTitle={formTitle}
          groupsData={groupsData}
        />
        <Modal
          visible={isAssignVehicleVisible}
          title="Assign vehicle"
          onOk={() => {
            JobService.updateVehicle({
              id: selectedRowKeys,
              assign_to: selectedVehicle,
            }).then((response) => {
              const newResult = response.data.map((r) => {
                return { ...r, key: r.job_uuid };
              });
              setData(newResult);
            });
            setIsAssignVehicleVisible(false);
          }}
          onCancel={() => {
            setIsAssignVehicleVisible(false);
          }}
        >
          <label>Vehicle : </label>
          <Select
            placeholder="Select vehicle"
            onChange={(value, element) => {
              setSelectedVehicle(value);
              // setSelectedVehicle(element.key);
            }}
            allowClear
          >
            {vehicleData.map((option) => (
              <Option key={option.vehicle_uuid} value={option.vehicle_uuid}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Modal>
        <Modal
          visible={isAssignGroupVisible}
          title="Assign group"
          onOk={() => {
            JobService.updateGroup({
              id: selectedRowKeys,
              group: selectedGroup,
            }).then((response) => {
              const newResult = response.data.map((r) => {
                return { ...r, key: r.job_uuid };
              });
              setData(newResult);
            });
            setIsAssignGroupVisible(false);
          }}
          onCancel={() => {
            setIsAssignGroupVisible(false);
          }}
        >
          <label>Group : </label>
          <Select
            placeholder="Select group"
            onChange={(value, element) => {
              setSelectedGroup(value);
              // setSelectedVehicle(element.key);
            }}
            allowClear
          >
            {groupsData ? (
              groupsData.map((option) => (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              ))
            ) : (
              <></>
            )}
          </Select>
        </Modal>
        <Modal
          visible={isChangeDateVisible}
          title="Change date"
          onOk={() => {
            JobService.updateDate({
              id: selectedRowKeys,
              date: selectedDate,
            }).then((response) => {
              const newResult = response.data.map((r) => {
                return { ...r, key: r.job_uuid };
              });
              setData(newResult);
            });
            setIsChangeDateVisible(false);
          }}
          onCancel={() => {
            setIsChangeDateVisible(false);
          }}
        >
          <label>Date : </label>
          <DatePicker
            onChange={(value, element) => {
              console.log("selecy dagde", dayjs(value));
              setSelectedDate(value);
              // setSelectedVehicle(element.key);
            }}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default Deliveries;
