import {
  Input,
  Button,
  Space,
  Table,
  Dropdown,
  Menu,
  Collapse,
  Modal,
  Row,
  Col,
  Tag,
  Progress,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  SearchOutlined,
  SettingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { green, red } from "@ant-design/colors";
import VehicleService from "../../services/VehicleService";
import VehicleUpdateForm from "./UpdateVehicle";
import CSVLink from "../../components/excel/CSVLink";
import TableFilterForm from "../../components/TableFilterForm";
import { isNullOrEmpty } from "../../utils/helper";
import dayjs from "dayjs";

const { CheckableTag } = Tag;
const { confirm } = Modal;
var searchInput = React.createRef();

const Vehicles = () => {
  const [loading, setLoading] = useState(true);
  const [setSearchText] = useState("");
  const [setSearchColumn] = useState("");
  const [data, setData] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [isHidden, setIsHidden] = useState();
  const [selectedRowKeys, selectRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [isFilterOptionsVisible, setIsFilterOptionsVisible] = useState(false);
  const [isUseTag, setIsUseTag] = useState(false);
  const [searchTags, setSearchTags] = useState({});
  const [searchTagsValue, setSearchTagsValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([
    "name",
    "driver_id",
    "groups",
    "zones",
  ]);
  const [selectedTagsLabel, setSelectedTagsLabel] = useState([
    "Name",
    "Driver ID",
    "Groups",
    "Zones",
  ]);

  const searchTagsLabel = {
    name: "Name",
    driver_id: "Driver ID",
    groups: "Groups",
    zones: "Zones",
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await VehicleService.getAll().then(
        (response) => {
          const newResult = response.data.map((r) => {
            return { ...r, key: r.vehicle_uuid };
          });
          setData(newResult);
          console.log(newResult);
        },
        (error) => {
          setData(null);
        }
      );
      setLoading(false);
    })();
  }, []);

  const getColumnSearchProps = (dataIndex) => {
    const customFilter = ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, textAlign: "right" }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          {/* <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button> */}
          <Button
            onClick={() => handleReset(clearFilters)}
            type="link"
            size="small"
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              // this.setState({
              //   searchText: selectedKeys[0],
              //   searchedColumn: dataIndex,
              // });
              setSearchText(selectedKeys[0]);
              setSearchColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    );
    const customFilterIcon = (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    );
    const customOnFilter = (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    const customOnFilterDropdownVisibleChange = (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    };
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
    return {
      filterDropdown: customFilter,
      filterIcon: customFilterIcon,
      onFilter: customOnFilter,
      onFilterDropdownVisibleChange: customOnFilterDropdownVisibleChange,
    };
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: "100px",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("name"),
    },
    {
      title: "Driver ID",
      dataIndex: "driver_id",
      key: "driver_id",
      // width: "200px",
    },
    {
      title: "Groups",
      dataIndex: "groups",
      key: "groups",
      // width: "150px",
    },
    {
      title: "Zones",
      dataIndex: "zones",
      key: "zones",
      // width: "100px",
    },
    {
      title: "Current Speed",
      dataIndex: "current_speed",
      key: "current_speed",
      // width: "90px",
    },
    {
      title: "Max Speed",
      dataIndex: "max_speed",
      key: "max_speed",
      // width: "90px",
    },
    {
      title: "Average Speed",
      dataIndex: "average_speed",
      key: "average_speed",
      // width: "90px",
    },
    {
      title: "Distance",
      dataIndex: "distance_travelled",
      key: "distance_travelled",
      // width: "90px",
    },
    {
      title: "Battery level",
      dataIndex: "battery_level",
      key: "battery_level",
      render: (text, record) => {
        let element;
        if (record.battery_level) {
          let percentage = parseFloat(record.battery_level) * 100;
          element = (
            <Progress
              format={(percent) => `${percent}%`}
              percent={percentage}
              steps={5}
              size="small"
              strokeColor={percentage > 40 ? green[6] : red[6]}
            />
          );
        } else {
          element = <></>;
        }
        return element;
      },
      // width: "90px",
    },
    // {
    //   title: "Can Grab Marketplace Jobs",
    //   dataIndex: "canGrabMarketplaceJobs",
    //   key: "canGrabMarketplaceJobs",
    //   // width: "120px",
    // },
    // {
    //   title: "GPS",
    //   dataIndex: "gps",
    //   key: "gps",
    //   // width: "60px",
    // },
    // {
    //   title: "Current Location",
    //   dataIndex: "currentLocation",
    //   key: "currentLocation",
    //   // width: "90px",
    // },
    {
      title: "Heading To",
      dataIndex: "headingTo",
      key: "headingTo",
      // width: "90px",
    },
    {
      title: "Last Connected",
      dataIndex: "lastConnected",
      key: "lastConnected",
      // width: "30%",
    },
    // {
    //   title: "Last Tracked",
    //   dataIndex: "lastTracked",
    //   key: "lastTracked",
    //   // width: "30%",
    // },
    // {
    //   title: "Connection",
    //   dataIndex: "connection",
    //   key: "connection",
    //   // width: "30%",
    // },
    // {
    //   title: "Checked In At",
    //   dataIndex: "checkedInAt",
    //   key: "checkedInAt",
    //   // width: "30%",
    // },
    {
      title: "Last POD",
      dataIndex: "lastPOD",
      key: "lastPOD",
      // width: "30%",
    },
    {
      title: "Map",
      dataIndex: "map",
      key: "map",
      // width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          icon={<DeleteOutlined />}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            showConfirm(record);
          }}
        >
          Delete
        </Button>
      ),
      // width: "30%",
    },
    // {
    //   title: "Connection",
    //   dataIndex: "connection",
    //   key: "connection",
    //   render: (tags) => (
    //     <span>
    //       {tags.map((tag) => {
    //         let color = "red";
    //         if (tag === "on") {
    //           color = "green";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </span>
    //   ),
    // width: "30%",
    //},
    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   key: "age",
    //   width: "20%",
    //   sorter: (a, b) => a.age - b.age,
    //   sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    //   // ...getColumnSearchProps("address"),
    //   sorter: (a, b) => a.address.localeCompare(b.address),
    //   sortDirections: ["descend", "ascend"],
    // },
  ];

  function showConfirm(record) {
    confirm({
      title: "Are you sure to delete this item?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        VehicleService.remove(record.vehicle_uuid).then((response) => {
          const newResult = response.data.map((r) => {
            return { ...r, key: r.vehicle_uuid, color: "#ddd" };
          });
          setData(newResult);
        });
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (selectedRowKeys) => {
    selectRows(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //   function onChange(pagination, filters, sorter, extra) {
  //     console.log("params", pagination, filters, sorter, extra);
  //   }
  //   function onShowSizeChange(current, pageSize) {
  //     console.log(current, pageSize);
  //   }

  async function onUpdate(vehicleId, newVehicle) {
    VehicleService.update(vehicleId, newVehicle).then(
      (response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.vehicle_uuid };
        });
        setData(newResult);
      },
      (error) => {
        setData(null);
      }
    );
    setVehicleId(null);
    setVisible(false);
  }

  async function onCreate(values) {
    VehicleService.create(values)
      .then((response) => {
        console.log(response.data);

        var newData = [...data];
        newData.push(response.data);
        setData(newData);
      })
      .catch((e) => {
        console.log(e);
      });

    setVisible(false);
  }

  function onCancel(form) {
    form.resetFields();
    setVehicleId(null);
    setVisible(false);
  }

  function handleMenuClick(e) {
    console.log("click", e);
  }
  const actionsMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Assign Groups</Menu.Item>
      <Menu.Item key="2">Unassign Groups</Menu.Item>
      <Menu.Item key="3">Delete</Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu onClick={handleMenuClick}>
      <CSVLink
        data={data}
        headers={columns}
        filename={"Vehicles_" + dayjs().format("YYYYMMDDHHmmss") + ".xlsx"}
      >
        <Menu.Item key="excel">As excel</Menu.Item>
      </CSVLink>
    </Menu>
  );
  //#region Search panel
  // const genExtra = () => (
  //   <SettingOutlined
  //     onClick={(event) => {
  //       event.stopPropagation();
  //       // customizeFilterOptions();
  //       setIsFilterOptionsVisible(true);
  //     }}
  //   />
  // );
  // function customizeFilterOptions() {
  //   const modal = Modal.info({
  //     title: "Filter options",
  //     content: <Row gutter={24}>{getFields()}</Row>,
  //     icon: <></>,
  //     onOk: () => {
  //       modal.destroy();
  //     },
  //   });
  // }
  //#endregion

  //#region Search Tags
  // const getFields = () => {
  //   const filterFields = ["name", "driver_id", "groups", "zones"];
  //   const filterFieldsLabel = ["Name", "Driver ID", "Groups", "Zones"];
  //   const children = [];
  //   for (let i = 0; i < filterFields.length; i++) {
  //     children.push(
  //       <Col span={8} key={i}>
  //         <CheckableTag
  //           style={{ fontSize: "14px" }}
  //           key={filterFields[i]}
  //           checked={selectedTags.indexOf(filterFields[i]) > -1}
  //           onChange={(checked) =>
  //             handleChange(filterFields[i], filterFieldsLabel[i], checked)
  //           }
  //         >
  //           {filterFieldsLabel[i]}
  //         </CheckableTag>
  //       </Col>
  //     );
  //   }
  //   return children;
  // };

  function onRemoveSearchedTag(value) {
    // searchTags.splice(value, 1);
    delete searchTags[value];
    delete searchTagsValue[value];
    console.log("delete", searchTags, searchTagsValue);
    if (Object.entries(searchTags).length === 0) {
      setIsUseTag(false);
    }
  }

  const onAddEditFilter = () => {
    setIsUseTag(false);
  };

  function onClearFilter() {
    setSearchTags({});
    setSearchTagsValue({});
    setIsUseTag(false);

    (async () => {
      //   setLoading(true);
      await VehicleService.getAll().then((response) => {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.vehicle_uuid };
        });
        setData(newResult);
        //   setLoading(false);
      });
    })();
  }

  const searchCriteria = () => {
    let children = [];
    let searchTagsButton = {};
    // for (let i = 0; i < searchTags.length; i++) {
    // console.log("adasd", searchTags);
    for (const key in searchTags) {
      children.push(
        <Tag key={key} closable onClose={() => onRemoveSearchedTag(key)}>
          {/* {Object.entries(searchTags[i]).join().replace(",", ":")} */}
          {searchTagsLabel[key] + " : " + searchTags[key]}
        </Tag>
      );
    }
    // }
    searchTagsButton = (
      <>
        {children}
        <Space>
          <Button size="small" type="primary" onClick={onAddEditFilter}>
            Add/Edit filter
          </Button>
          <Button size="small" type="primary" onClick={onClearFilter}>
            Clear
          </Button>
        </Space>
      </>
    );
    return searchTagsButton;
  };

  //#endregion

  //#region Search filter form
  // function handleChange(tag, tagLabel, checked) {
  //   const nextSelectedTags = checked
  //     ? [...selectedTags, tag]
  //     : selectedTags.filter((t) => t !== tag);
  //   const nextSelectedTagsLabel = checked
  //     ? [...selectedTagsLabel, tagLabel]
  //     : selectedTagsLabel.filter((t) => t !== tagLabel);
  //   // console.log("You are interested in: ", nextSelectedTags, checked);
  //   setSelectedTags(nextSelectedTags);
  //   setSelectedTagsLabel(nextSelectedTagsLabel);
  // }

  const onSearch = (values) => {
    console.log("onsearch", values);
    let searchedTags = [];
    let searchedTagsValue = {};
    var obj = {};

    for (let i = 0; i < selectedTags.length; i++) {
      console.log("onsearch1", values[selectedTags[i]]);
      if (!isNullOrEmpty(values[selectedTags[i]])) {
        obj[selectedTags[i]] = values[selectedTags[i]];
        searchedTagsValue[selectedTags[i]] = values[selectedTags[i]];

        // searchedTags.push(obj);

        // searchedTags.push(selectedTags[i], values[selectedTags[i]]);
        // setSearchTags(selectedTags.slice());
      }
    }
    if (searchedTags) {
      setSearchTags(obj);
      setSearchTagsValue(searchedTagsValue);
      setIsUseTag(true);
    }
    console.log("v: " + JSON.stringify(values));
    VehicleService.findByName(values).then((response) => {
      console.log("v1: " + response.data);
      if (response.data) {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.vehicle_uuid, color: "#ddd" };
        });
        setData(newResult);
      }
    });
  };
  //#endregion

  return (
    <Spin spinning={loading}>
      <div className="site-layout-content" style={{ textAlign: "right" }}>
        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
          {isUseTag ? (
            searchCriteria()
          ) : (
            <Collapse defaultActiveKey={1}>
              <Collapse.Panel
                header="Search"
                key="1"
                // showArrow={false}
                // extra={genExtra()}
              >
                <TableFilterForm
                  fields={selectedTags}
                  fieldsLabel={selectedTagsLabel}
                  values={searchTagsValue}
                  onSearch={onSearch}
                />
              </Collapse.Panel>
            </Collapse>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => {
              setVisible(true);
              setFormTitle("Add vehicle");
              setIsHidden(true);
            }}
          >
            Add+
          </Button>
          <Dropdown overlay={actionsMenu} trigger={["click"]}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              // onClick={() => {}}
            >
              Actions
            </Button>
          </Dropdown>
          <Dropdown overlay={exportMenu} trigger={["click"]}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              // onClick={() => {}}
            >
              Export
            </Button>
          </Dropdown>
        </Space>
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          // onChange={onChange}
          // rowClassName={(record) => record.color.replace("#", "")}
          rowSelection={rowSelection}
          pagination={{ showSizeChanger: true }}
          onRow={(r) => {
            return {
              onClick: (e) => {
                setVisible(true);
                setVehicleId(r.key);
                setIsHidden(false);
                setFormTitle("Update vehicle");
                //   console.log("key", r.key);
              },
              style: { cursor: "pointer" },
            };
          }}
        ></Table>
        <VehicleUpdateForm
          visible={visible}
          vehicleId={vehicleId}
          title={formTitle}
          isHidden={isHidden}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />
        {/* <Modal
        visible={isFilterOptionsVisible}
        title="Filter options"
        closable={false}
        onOk={() => {
          setIsFilterOptionsVisible(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsFilterOptionsVisible(false);
            }}
          >
            OK
          </Button>,
        ]}
        // onCancel={() => {
        //   setIsFilterOptionsVisible(false);
        // }}
      >
        <Row gutter={24}>{getFields()}</Row>
      </Modal> */}
      </div>
    </Spin>
  );
};

export default Vehicles;
