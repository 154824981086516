import http from "../http-common";
const create = (data) => {
  return http.post("/settings", data);
};
const getAllByEntity = (entity) => {
  return http.get(`/settings/${entity}`);
};
const remove = (id, entity) => {
  return http.delete(`/settings/${entity}/${id}`);
};

const settingObj = {
  create,
  getAllByEntity,
  remove,
};

export default settingObj;
