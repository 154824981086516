import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Transfer, Select } from "antd";
import TransferBox from "../../components/TransferBox";
import JobService from "../../services/JobService";

const { Option } = Select;

const columns = [
  {
    title: "Vehicle Name",
    dataIndex: "vehicle_name",
    key: "vehicle_name",
  },
  {
    title: "Info Received",
    dataIndex: "info_received",
    key: "info_received",
  },
  {
    title: "Picked Up",
    dataIndex: "picked_up",
    key: "picked_up",
  },
  {
    title: "Out For Delivery",
    dataIndex: "out_for_delivery",
    key: "out_for_delivery",
  },
  {
    title: "Completed",
    dataIndex: "completed",
    key: "completed",
  },
  {
    title: "Partially Completed",
    dataIndex: "partially_completed",
    key: "partially_completed",
  },
  {
    title: "Failed",
    dataIndex: "failed",
    key: "failed",
  },
  {
    title: "Last POD At",
    dataIndex: "last_pod_at",
    key: "last_pod_at",
  },
  {
    title: "Connected At",
    dataIndex: "connected_at",
    key: "connected_at",
  },
];

const AssignedJob = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState();
  const [vehicleData, setVehicleData] = useState([]);
  const [fromVehicleJobIdData, setFromVehicleJobIdData] = useState([]);
  const [toVehicleJobIdData, setToVehicleJobIdData] = useState([]);
  const [oriFromVehicleJobIdData, setOriFromVehicleJobIdData] = useState([]);
  const [oriToVehicleJobIdData, setOriToVehicleJobIdData] = useState([]);
  const [fromVehicle, setFromVehicle] = useState();
  const [toVehicle, setToVehicle] = useState();

  useEffect(() => {
    JobService.getAssignedJob().then((resp) => {
      // console.log("assignenk:", resp.data);
      setData(resp.data);
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // console.log("fromVehicleJobIdData", fromVehicleJobIdData);
    // console.log("toVehicleJobIdData", toVehicleJobIdData);
    // console.log("oriFromVehicleJobIdData", oriFromVehicleJobIdData);
    // console.log("oriToVehicleJobIdData", oriToVehicleJobIdData);
    // console.log("fromVehicle", fromVehicle);
    // console.log("toVehicle", toVehicle);

    if (!compareArray(oriFromVehicleJobIdData, fromVehicleJobIdData)) {
      const job = { assign_to: fromVehicle, id: fromVehicleJobIdData };
      JobService.updateVehicle(job)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    }
    if (!compareArray(oriToVehicleJobIdData, toVehicleJobIdData)) {
      const job = { assign_to: toVehicle, id: toVehicleJobIdData };
      JobService.updateVehicle(job)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
        });
    }
    setIsModalVisible(false);
  };

  const compareArray = (array1, array2) => {
    const array2Sorted = array2.slice().sort();
    var result =
      array1.length === array2.length &&
      array1
        .slice()
        .sort()
        .every(function (value, index) {
          return value === array2Sorted[index];
        });
    // console.log("COOMPARARARY REYSK", result);
    return result;
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="site-layout-content">
        <Button type="primary" onClick={() => showModal()}>
          Transfer jobs
        </Button>
        <Table
          dataSource={data}
          columns={columns}
          scroll={{ x: true }}
          pagination={{ showSizeChanger: true }}
          style={{ marginTop: 10 }}
        />
      </div>
      <Modal
        title="Transfer job"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        destroyOnClose
      >
        <TransferBox
          fromVehicleJobIdData={fromVehicleJobIdData}
          setFromVehicleJobIdData={setFromVehicleJobIdData}
          toVehicleJobIdData={toVehicleJobIdData}
          setToVehicleJobIdData={setToVehicleJobIdData}
          oriFromVehicleJobIdData={oriFromVehicleJobIdData}
          setOriFromVehicleJobIdData={setOriFromVehicleJobIdData}
          oriToVehicleJobIdData={oriToVehicleJobIdData}
          setOriToVehicleJobIdData={setOriToVehicleJobIdData}
          fromVehicle={fromVehicle}
          setFromVehicle={setFromVehicle}
          toVehicle={toVehicle}
          setToVehicle={setToVehicle}
        />
      </Modal>
    </>
  );
};

export default AssignedJob;
