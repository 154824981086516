import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";

import { Form, Input, Button, message, Checkbox, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AuthenticateService from "../services/AuthService";
import loginBackgroundImage from "../../src/email-location-tracking-1.jpg";

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const inputRef = React.useRef(null);

  useEffect(() => {
    var user = localStorage.getItem("user");
    console.log("loginuser", user);
    if (user && user.is_new) {
      console.log("fdfff", user.is_new);
      navigate("/MyProfile", { state: { id: 1, prev: "step1" } });
    }
    if (isAuthenticated) {
      //   history.push("/Dashboard");
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  async function onFinish(values) {
    await AuthenticateService.login(values)
      .then(
        (response) => {
          if (response.data.accessToken) {
            localStorage.setItem("user", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            userHasAuthenticated(true);
            //   history.push("/Dashboard");
            navigate("/");
          }
          // else {
          //   inputRef.current.click();
          // }
        },
        (error) => {
          // const resMessage =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          console.log(error);
          inputRef.current.click();
        }
      )
      .catch((e) => {
        console.log(e);
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const errorMessage = () => {
    message.error("Invalid credential");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${loginBackgroundImage})`,
        height: "89vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        // className="site-layout-content"
        style={{
          paddingTop: "15vh",
          // paddingLeft: "70vh",
          // paddingRight: "70vh",
        }}
      >
        <Button
          ref={inputRef}
          onClick={errorMessage}
          style={{ display: "none" }}
        >
          Error
        </Button>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12, offset: 6 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <Form
              style={{
                paddingTop: "80px",
                paddingBottom: "40px",
                backgroundColor: "rgba(192,192,192,0.5)",
              }}
              name="normal_login"
              className="login-form"
              // labelCol={{ span: 8 }}
              wrapperCol={{
                sm: { span: 20, offset: 2 },
                md: { span: 20, offset: 2 },
                lg: { span: 20, offset: 2 },
                xl: { span: 13, offset: 5 },
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },
                ]}
              >
                <Input
                  wrapperCol={{ span: 4, offset: 10 }}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button>
                {"   "}create an account <a href="/Register">here</a>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Login;
