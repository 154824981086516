import React, { useState } from "react";
import { Table, Collapse } from "antd";
import TableFilterForm from "../../components/DJTableFilterForm";
import JobService from "../../services/JobService";
import { isNullOrEmpty } from "../../utils/helper";

const DailyJob = () => {
  const [isUseTag, setIsUseTag] = useState(false);
  const [data, setData] = useState();
  const [selectedRowKeys, selectRows] = useState();
  const [searchTags, setSearchTags] = useState({});
  const [searchTagsValue, setSearchTagsValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState(["job"]);
  const [selectedTagsLabel, setSelectedTagsLabel] = useState(["Job"]);
  const columns = [
    {
      title: "Job",
      dataIndex: "job",
      key: "job",
    },
  ];

  const searchCriteria = () => {};
  const onSelectChange = (selectedRowKeys) => {
    selectRows(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (values) => {
    console.log("onsearch", values);
    let searchedTags = [];
    let searchedTagsValue = {};
    var obj = {};

    for (let i = 0; i < selectedTags.length; i++) {
      console.log("onsearch1", values[selectedTags[i]]);
      if (!isNullOrEmpty(values[selectedTags[i]])) {
        obj[selectedTags[i]] = values[selectedTags[i]];
        searchedTagsValue[selectedTags[i]] = values[selectedTags[i]];
      }
    }
    if (searchedTags) {
      setSearchTags(obj);
      setSearchTagsValue(searchedTagsValue);
      setIsUseTag(true);
    }
    console.log("v: " + JSON.stringify(values));
    JobService.findByName(values).then((response) => {
      console.log("v1: " + response.data);
      if (response.data) {
        const newResult = response.data.map((r) => {
          return { ...r, key: r.id, color: "#ddd" };
        });
        setData(newResult);
      }
    });
  };
  return (
    <div className="site-layout-content" style={{ textAlign: "right" }}>
      <div style={{ textAlign: "left", paddingBottom: "5px" }}>
        {isUseTag ? (
          searchCriteria()
        ) : (
          <Collapse defaultActiveKey={1}>
            <Collapse.Panel
              header="Search"
              key="1"
              // showArrow={false}
              // extra={genExtra()}
            >
              <TableFilterForm
                fields={selectedTags}
                fieldsLabel={selectedTagsLabel}
                values={searchTagsValue}
                onSearch={onSearch}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: true }}
        // onRow={(r) => {
        //   return {
        //     onClick: (e) => {
        //       setVisible(true);
        //       setVehicleId(r.key);
        //       setFormTitle("Update vehicle");
        //       //   console.log("key", r.key);
        //     },
        //     style: { cursor: "pointer" },
        //   };
        // }}
      ></Table>
    </div>
  );
};

export default DailyJob;
