import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Select, DatePicker } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { JobStatusEnum } from "../../enumClass/JobStatus";

const TableFilterForm = ({ fields, fieldsLabel, values, onSearch }) => {
  const [expand, setExpand] = useState(false);
  const [isExpandVisible, setExpandVisible] = useState(false);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    console.log("jobfilter: ", values);
    form.setFieldsValue({
      id: values["id"],
      batch_num: values["batch_num"],
      address: values["address"],
      postal_code: values["postal_code"],
      contact: values["contact"],
      date: values["date"],
      recipient: values["recipient"],
      pts: values["pts"],
      quantity: values["quantity"],
      group: values["group"],
      assign_to: values["assign_to"],
      job_status: values["job_status"],
      instructions: values["instructions"],
      marking_number: values["marking_number"],
      pod_time: values["pod_time"],
      reasons: values["reasons"],
      receivedBy: values["receivedBy"],
    });
  }, [form]);

  const getFields = () => {
    const children = [];
    const { Option } = Select;
    var count;
    const jobStatusOptions = Object.entries(JobStatusEnum)
      // .sort(([, a], [, b]) => a - b)
      .map(([value, label]) => {
        return (
          <Option key={value} value={value}>
            {label}
          </Option>
        );
      });
    if (fields.length > 6) {
      if (!isExpandVisible) {
        setExpandVisible(true);
      }
    }
    if (!expand && fields.length > 6) {
      count = 6;
    } else {
      count = fields.length;
    }

    for (let i = 0; i < count; i++) {
      children.push(
        <Col style={{ marginBottom: "-15px" }} span={8} key={i}>
          <Form.Item name={fields[i]} label={fieldsLabel[i]}>
            {fields[i] === "job_status" ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
              >
                {jobStatusOptions}
              </Select>
            ) : fields[i] === "date" || fields[i] === "pod_time" ? (
              <RangePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
      );
    }

    return children;
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      // className="ant-advanced-search-form"
      onFinish={onSearch}
    >
      <Row gutter={24}>{getFields()}</Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          {isExpandVisible ? (
            <a
              style={{
                fontSize: 12,
              }}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? (
                <>
                  <UpOutlined />
                  Collapse
                </>
              ) : (
                <>
                  <DownOutlined />
                  Expand
                </>
              )}
            </a>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default TableFilterForm;
