import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Input,
  Space,
  Card,
  Row,
  Col,
  Button,
  message,
  Form,
} from "antd";
import TopicMenu from "../../components/TopicMenu";
import AuthenticateService from "../../services/AuthService";
import {
  UserOutlined,
  LockOutlined,
  SwapOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const UserProfile = () => {
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const [user, setUser] = useState({});

  useEffect(() => {
    async function fetchData() {
      await AuthenticateService.getUser().then((response) => {
        console.log("pppppppp", response.data);
        setUser(response.data);
        // form.setFieldsValue({
        //   name: response.data.username,
        //   email: response.data.email,
        // });
      });
    }
    fetchData();
  }, []);

  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };

  const changePassword = (values) => {
    console.log("CPPP", values);
    AuthenticateService.changePassword(values).then(
      (response) => {
        message.success("Changed password successfully!");
        //   setResult("Success");
        //   inputRef.current.click();
      },
      (error) => {
        message.error("Error occured");
        //   setResult("failed");
        //   inputRef.current.click();
      }
    );
  };

  const topics = [
    { name: "My Profile", icon: <UserOutlined /> },
    { name: "Change Password", icon: <LockOutlined /> },
    { name: "Two Factor Auth", icon: <FileProtectOutlined /> },
    { name: "Support", icon: <CustomerServiceOutlined /> },
    { name: "Switch Organization", icon: <SwapOutlined /> },
  ];

  const content = [
    <Card title="My profile" style={{ maxWidth: 500 }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Input
          placeholder="Name"
          prefix={<UserOutlined />}
          value={user["name"]}
          disabled
        />
        <Input
          placeholder="Email"
          prefix={<MailOutlined />}
          value={user["email"]}
          disabled
        />
      </Space>
    </Card>,
    <Card title="Change password" style={{ maxWidth: 500 }}>
      {/* <Space direction="vertical" size="middle" style={{ display: "flex" }}> */}
      <Form onFinish={changePassword}>
        <Form.Item
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="Current password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input
            type="password"
            placeholder="New password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder="Confirm password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      {/* </Space> */}
    </Card>,
    <Card
      title="Two factor authentication"
      style={{ maxWidth: 500 }}
      extra={<Button type={"primary"}>Enable</Button>}
    >
      <Row>
        <Col>
          Two factor authentication(2FA) is a security process in which you must
          provide an alternate factor to verify yourself prior to logging into
          your account. This alternative authentication involved the use of
          Google Authenticator App.
        </Col>
      </Row>
    </Card>,
  ];

  return (
    <Layout>
      <Sider
        breakpoint="xs"
        collapsedWidth="30"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu theme="light" mode="inline">
          <TopicMenu
            topics={topics}
            selectedKey={selectedKey}
            changeSelectedKey={changeSelectedKey}
          />
        </Menu>
      </Sider>
      <Layout>
        {/* <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0 }}
        /> */}
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360, background: "whitesmoke" }}
          >
            {content[contentIndex]}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};

export default UserProfile;
