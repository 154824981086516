import http from "../http-common";
const login = async (credential) => {
  return await http.post("/auth/signin", credential);
};
const refreshToken = () => {
  return http.post("/auth/refreshToken", {
    refreshToken: localStorage.getItem("refreshToken"),
  });
};
const register = (data) => {
  return http.post("/auth/signup", data);
};
const getUser = () => {
  return http.get(`/users`);
};
const updateUser = (email, data) => {
  return http.put(`/users/${email}`, data);
};
const changePassword = (data) => {
  return http.put(`auth/changePassword`, data);
};
const refreshAccessToken = (data) => {
  return http.post("/user", data);
};
const getCaptchaImage = () => {
  return http.get("/captcha");
};
const getCurrentUser = () => {
  return localStorage.getItem("user");
};
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("refreshToken");
};

const authObject = {
  login,
  refreshToken,
  logout,
  register,
  getUser,
  updateUser,
  changePassword,
  refreshAccessToken,
  getCaptchaImage,
  getCurrentUser,
};

export default authObject;
